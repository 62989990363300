import axios from 'axios';

const SOCKET_URL = 'wss://127.0.0.1:13579/';
const HEARTBEAT_MSG = '--heartbeat--';
const MISSED_HEARTBEATS_LIMIT = 3;

let webSocket = null;
let response = '';

function connect() {
  if (webSocket && webSocket.readyState < 2) {
    console.log(`Reusing WebSocket connection [state = ${webSocket.readyState}]: ${webSocket.url}`);
    return Promise.resolve(webSocket);
  }

  return new Promise((resolve, reject) => {
    webSocket = new WebSocket(SOCKET_URL + '?withCredentials=true');
    webSocket.onopen = () => {
      console.log(
        `WebSocket connection opened [state = ${webSocket.readyState}]: ${webSocket.url}`,
      );
      resolve(webSocket);
    };

    webSocket.onerror = (err) => {
      console.error('WebSocket connection error:', err);
      reject(err);
    };

    webSocket.onclose = (event) => {
      if (!event.wasClean) {
        openDialog();
      }
      console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`);
    };
  });
}

function sendMessage(message, onSuccess, onError) {
  connect()
    .then((socket) => {
      socket.send(JSON.stringify(message));
      socket.onmessage = async ({ data }) => {
        const response = JSON.parse(data);
        if (response.status === true && response.body && response.body.result) {
          const signatureResult = Array.isArray(response.body.result)
            ? response.body.result.join('')
            : response.body.result;
          onSuccess(signatureResult);
        } else {
          const errorCode = response.code || 'Signing failed';
          console.error('Signing failed with error code:', errorCode);
          if (typeof onError === 'function') {
            onError(errorCode);
          }
        }
      };
    })
    .catch((err) => {
      if (typeof onError === 'function') {
        onError(`WebSocket connection failed: ${err.message}`);
      }
    });
}

export function signAuthXML(xmlData, onSuccess, onError) {
  const base64Signature = xmlData;
  const signatureType = 'xml';
  var decode = false;
  var encapsulate = false;
  var digested = false;
  var localeRadio = 'ru';
  var tsaProfile = null;
  var selectedStorages = ['PKCS12', 'AKKaztoken', 'AKKzIDCard'];

  const message = {
    module: 'kz.gov.pki.knca.basics',
    method: 'sign',
    args: {
      allowedStorages: selectedStorages,
      format: signatureType,
      data: base64Signature,
      signingParams: {
        decode: decode,
        encapsulate: encapsulate,
        digested: digested,
        tsaProfile: tsaProfile,
      },
      signerParams: {
        extKeyUsageOids: [],
        chain: [],
      },
      locale: localeRadio,
    },
  };

  sendMessage(message, onSuccess, onError);
}

function openDialog() {
  if (confirm('Ошибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК') === true) {
    location.reload();
  }
}
