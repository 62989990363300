import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Container, ContentBox, TitleBox, DataBox, MainInfoContent } from './InfoCreditStory.style';
import {
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import {
  FileItem,
  FileLink,
} from 'components/ApplicationInvestor/ApplicationDocumentsList/ApplicationDocumentsList.style';
import IconWorkHistory from 'assets/img/IconWorkHistory.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDocuments from 'assets/img/IconDocuments.svg';
import { IsPro } from 'components/BankStatement/BankStatement.style';
import { ErrorModal } from 'components/common/ErrorModal';

export interface InfoCreditProps {
  num: string;
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const InfoCreditStory: React.FC<InfoCreditProps> = ({ id, num }) => {
  const [creditHistory, setCreditHistory] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isAdditionalReportExpanded, setIsAdditionalReportExpanded] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}`,
          { withCredentials: true },
        );
        setCreditHistory(response.data.getReportResultDTO.cigResultDTO.resultDTO.rootDTO);
      } catch (err) {
        setError('Ошибка при загрузке данных кредитной истории.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}/pdf`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `Credit_History_${id}.pdf`);
    } catch (err) {
      setError('Ошибка при скачивании PDF файла.');
    }
  };

  const closeErrorModal = () => {
    setError(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {error && (
        <ErrorModal
          headerError='Произошла ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeErrorModal}
        />
      )}
      <Container>
        <ExpandableSection>
          <MainInfoTitle onClick={() => setIsAdditionalReportExpanded(!isAdditionalReportExpanded)}>
            <img src={IconWorkHistory} alt='icon' />
            Скачать расширенный кредитный отчет
            <IsPro>PRO</IsPro>
            <ArrowIcon src={isAdditionalReportExpanded ? IconArrowDown : IconArrow} alt='arrow' />
          </MainInfoTitle>
          {isAdditionalReportExpanded && (
            <MainInfoBox>
              <MainInfoContent>
                <FileItem>
                  <FileLink onClick={() => handleDownloadPDF()}>
                    <div>
                      <img src={IconDocuments} alt='download icon' />
                      Кредитный отчет
                    </div>
                    <p>скачать</p>
                  </FileLink>
                </FileItem>
              </MainInfoContent>
            </MainInfoBox>
          )}
        </ExpandableSection>
        <ExpandableSection>
          <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
            <img src={IconWorkHistory} alt='icon' />
            Кредитная история
            <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
          </MainInfoTitle>
          {isMainInfoExpanded && (
            <MainInfoBox>
              <MainInfoContent>
                <ContentBox>
                  <TitleBox>Ограничение доступа</TitleBox>
                  <DataBox>
                    Субъект не найден в списке лиц, ограничивавших доступ к своему кредитному отчету
                  </DataBox>
                </ContentBox>
                <ContentBox>
                  <TitleBox>Количество произведенных участниками ПКБ запросов</TitleBox>
                  <DataBox>
                    {creditHistory?.numberOfQueriesDTO?.title
                      ? creditHistory.numberOfQueriesDTO.value
                      : 'Данные отсутствуют'}
                  </DataBox>
                </ContentBox>
                <ContentBox>
                  <TitleBox>Алиментщики</TitleBox>
                  <DataBox>
                    {creditHistory?.publicSourcesDTO?.qamqorAlimonyDTO?.statusDTO?.title
                      ? creditHistory.publicSourcesDTO.qamqorAlimonyDTO.statusDTO.value
                      : 'Данные отсутствуют'}
                  </DataBox>
                </ContentBox>
                <ContentBox>
                  <TitleBox>Действующие договора (просроченные)</TitleBox>
                  <DataBox>
                    {creditHistory?.summaryInformationDTO?.existingContractsDTO?.subjectRoleDTOList?.find(
                      (role: any) => role.type === 'Borrower',
                    )?.totalDebtOverdue?.title
                      ? `${
                          creditHistory.summaryInformationDTO.existingContractsDTO.subjectRoleDTOList.find(
                            (role: any) => role.type === 'Borrower',
                          ).totalDebtOverdue.title
                        }: ${
                          creditHistory.summaryInformationDTO.existingContractsDTO.subjectRoleDTOList.find(
                            (role: any) => role.type === 'Borrower',
                          ).totalDebtOverdue.value
                        }`
                      : 'Данные отсутствуют'}
                  </DataBox>
                </ContentBox>
              </MainInfoContent>
            </MainInfoBox>
          )}
        </ExpandableSection>
      </Container>
    </>
  );
};

export default InfoCreditStory;
