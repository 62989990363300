// UserCabinet.tsx
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  UserContainer,
  UserInfo,
  Input,
  ContentBox,
  ContactBox,
  ContainerAvatar,
  BoxAvatar,
  TitleAvatar,
  DescriptionAvatar,
  BoxAvatarImg,
  Circle,
} from './UserCabinet.style';
import { InputText } from 'components/common/Input/InputText';
import { NewButton } from 'components/common/Button/NewButton';
import { ErrorModal } from 'components/common/ErrorModal';
import { useAppContext } from 'contexts/AppContext';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const UserCabinet = () => {
  const [userData, setUserData] = useState<any | null>(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { userDataLight } = useAppContext();

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/ui/user`, {
        withCredentials: true,
      });
      setUserData(response.data);
      if (response.data.avatar) {
        // fetchAvatar(response.data.avatar);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setEmail(userData.notifications?.EMAIL?.value || '');
      if (userData.notifications.SMS.value) {
        const formattedPhone = formatPhone(userData.notifications.SMS.value) || '';
        setPhone(formattedPhone);
      }
      setInstagram(userData.instagram || '');
      setLinkedIn(userData.linkedIn || '');
    }
  }, [userData]);

  const { fio = 'Default Name', iin = '', roles = [] } = userData || {};

  const formatPhone = (phone: string) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = '+7';
      return [intlCode, ' (', match[1], ') ', match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  };

  const cleanPhone = (formattedPhone: string) => {
    return formattedPhone.replace(/\D/g, '');
  };

  const saveUserData = async () => {
    const url = `${BASE_URL}/api/v1/ui/user/save`;
    const cleanedPhone = cleanPhone(phone);
    const payload = {
      notifications: {
        SMS: {
          value: cleanedPhone,
          valid: false,
          active: true,
        },
        EMAIL: {
          value: email,
          valid: false,
          active: true,
        },
      },
      instagram,
      linkedIn,
    };

    setLoading(true);
    try {
      await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      setEditable(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAllChanges = async () => {
    await saveUserData();
  };

  const toggleEditable = () => {
    if (editable) {
      handleSaveAllChanges();
    } else {
      setEditable(true);
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        setErrorModalText('Размер файла превышает допустимый лимит в 2МБ.');
        setShowErrorModal(true);
        return;
      }
      uploadAvatar(file);
    }
  };

  const uploadAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/ui/user/upload_avatar`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Получаем ID нового аватара из ответа
      const newAvatarId = response.data.avatar;

      // Обновляем аватар и перезагружаем страницу
      const newAvatarUrl = `${BASE_URL}/images/avatar/${newAvatarId}`;
      setAvatarUrl(newAvatarUrl); // Обновляем состояние с новым URL
      window.location.reload(); // Перезагружаем страницу после загрузки аватара
    } catch (error) {
      console.error('Failed to upload avatar:', error);
      setErrorModalText('Не удалось загрузить аватар.');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContainer>
      <ContainerAvatar>
        <BoxAvatar>
          <div>
            <TitleAvatar>Фото профиля</TitleAvatar>
            <DescriptionAvatar>Рекомендовано 300х300</DescriptionAvatar>
          </div>
          <BoxAvatarImg>
            {userDataLight?.avatar ? <Circle avatarFileName={userDataLight.avatar} /> : <Circle />}
          </BoxAvatarImg>
        </BoxAvatar>
        <input
          type='file'
          accept='image/*'
          onChange={handleAvatarChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <NewButton
          text={userDataLight?.avatar ? 'Заменить фото' : 'Добавить фото'}
          onClick={() => fileInputRef.current?.click()}
          loading={loading}
          disabled={loading}
        />
      </ContainerAvatar>
      <ContentBox>
        <UserInfo>
          <strong>ФИО:</strong>
          <p>{fio}</p>
        </UserInfo>
        <UserInfo>
          <strong>ИИН:</strong>
          <p>{iin}</p>
        </UserInfo>
        <UserInfo>
          <strong>Роль:</strong>
          <p>{roles.join(', ')}</p>
        </UserInfo>
        <UserInfo className='col'>
          <strong>Email:</strong>
          <ContactBox>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              readOnly={!editable}
              disabled={!editable}
            />
          </ContactBox>
        </UserInfo>
        <UserInfo className='col'>
          <strong>Мобильный номер:</strong>
          <ContactBox>
            <Input
              mask='+7 (___) ___-__-__'
              replacement={{ _: /\d/ }}
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
              placeholder='Phone'
              $readOnly={!editable}
              $hasContent={!!phone}
              disabled={!editable}
            />
          </ContactBox>
        </UserInfo>
        <UserInfo className='col'>
          <strong>Instagram:</strong>
          <ContactBox>
            <InputText
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              placeholder='Instagram'
              readOnly={!editable}
              disabled={!editable}
            />
          </ContactBox>
        </UserInfo>
        <UserInfo className='col'>
          <strong>LinkedIn:</strong>
          <ContactBox>
            <InputText
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              placeholder='LinkedIn'
              readOnly={!editable}
              disabled={!editable}
            />
          </ContactBox>
        </UserInfo>
        <NewButton
          text={editable ? (loading ? 'Сохранение...' : 'Сохранить изменения') : 'Редактировать'}
          onClick={toggleEditable}
          disabled={loading}
        />
      </ContentBox>
      {showErrorModal && (
        <ErrorModal
          headerError='Ошибка'
          textError={errorModalText}
          buttonClose='Закрыть'
          onClick={() => setShowErrorModal(false)}
        />
      )}
    </UserContainer>
  );
};
