import React, { useState, useEffect } from 'react';
import { Container, Header, Tab, TabsContainer } from './CabinetAccount.style';
import { UserCabinet } from 'components/UserCabinet';
import { OrganizationCabinet } from 'components/OrganizationCabinet';
import UserTelegram from 'components/UserCabinet/UserTelegram/UserTelegram';
import { OfertsVersioning } from 'components/OfertsVersioning';
import { useLocation } from 'react-router-dom';

export const CabinetAccount = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'user';
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <Container>
      <Header>Учетная запись</Header>
      <TabsContainer>
        <Tab active={activeTab === 'user'} onClick={() => setActiveTab('user')}>
          Аккаунт
        </Tab>
        <Tab active={activeTab === 'organization'} onClick={() => setActiveTab('organization')}>
          Организация
        </Tab>
        <Tab active={activeTab === 'agreement'} onClick={() => setActiveTab('agreement')}>
          Соглашения и оферта
        </Tab>
        <Tab active={activeTab === 'notifications'} onClick={() => setActiveTab('notifications')}>
          Уведомления
        </Tab>
      </TabsContainer>
      {activeTab === 'user' && <UserCabinet />}
      {activeTab === 'agreement' && <OfertsVersioning />}
      {activeTab === 'organization' && <OrganizationCabinet />}
      {activeTab === 'notifications' && <UserTelegram />}
    </Container>
  );
};
