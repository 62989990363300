import styled from 'styled-components';
import Banner from 'assets/img/BannerRegisterModal.webp';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 0px;
  background-image: url(${Banner});
  background-size: cover;
  background-position: center;
  overflow-x: scroll;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  button {
    width: 440px;
  }
`;

const LogoImg = styled.img`
  width: 148px;
  height: 40px;
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  align-items: flex-start; /* Allows each block to grow independently */
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 488px;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #fff;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visibility */
  button {
    width: 100%;
  }
  input {
    margin-top: -20px;
  }
`;

const RightBlock = styled.div`
  flex: 1;
  max-width: 548px;
  position: relative;
  display: flex;
`;

const TextBox = styled.div`
  display: flex;
  max-width: 438px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-height: 186px;
  h3 {
    color: var(--Black-Main, #292d32);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 106%;
  }
  p {
    color: #5f5f5f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  span {
    color: var(--Black-Main, #292d32);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
  div {
    display: flex;
    gap: 8px;
  }
`;

const Line = styled.div`
  border: 1px solid #dee2e6;
  width: 100%;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 800px;
  width: 100%;
  button {
    width: 100%;
  }
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 460px;
  height: 100%;
  p {
    color: var(--Primary, #5784f7);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    width: 100%;
  }
  button {
    width: 100%;
  }
`;
const StatusCard = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  color: var(--Black-Main, #292d32);
  font-size: 18px;
  font-weight: 600;
  line-height: 106%; /* 21.2px */
  width: 100%;
  img {
    width: 20px;
    height: 20px;
  }
`;

const RefreshStatus = styled.p`
  color: var(--Primary, #01aeb7);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const OferBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
`;

const Pdf = styled.div`
  width: 910px;
  height: 510px;
  @media (max-height: 790px) {
    height: 410px;
  }
`;

export {
  Container,
  Box,
  LogoImg,
  BoxContent,
  TextBox,
  Line,
  ModalContainer,
  StatusBox,
  StatusCard,
  RefreshStatus,
  LeftBlock,
  RightBlock,
  OferBox,
  Pdf,
};
