import $ from 'jquery';
import { useState } from 'react';
import axios from 'axios';
var SOCKET_URL = 'wss://127.0.0.1:13579/';
var response = '';

async function sign(signature, url, contractId) {
  await request(signature, url, contractId);
  return response.toString();
}

function connect() {
  if (connect.webSocket && connect.webSocket.readyState < 2) {
    console.log(
      'reusing the socket connection [state = ' +
        connect.webSocket.readyState +
        ']: ' +
        connect.webSocket.url,
    );
    return Promise.resolve(connect.webSocket);
  }

  return new Promise(function (resolve, reject) {
    const socketUrlWithCredentials = SOCKET_URL + '?withCredentials=true';
    connect.webSocket = new WebSocket(socketUrlWithCredentials);

    connect.webSocket.onopen = function () {
      console.log(
        'socket connection is opened [state = ' +
          connect.webSocket.readyState +
          ']: ' +
          connect.webSocket.url,
      );
      resolve(connect.webSocket);
    };

    connect.webSocket.onerror = function (err) {
      unblockScreen();
      console.error('socket connection error : ', err);
      reject(err);
    };

    connect.webSocket.onclose = function (event) {
      if (event.wasClean) {
        console.error('socket connection is closed ');
      } else {
        console.log('Connection error');
        openDialog();
      }
      console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    };
  });
}

export async function request(signature, url, contractId) {
  var selectedStorages = [];
  var storageCheckboxes = document.querySelectorAll('input[name=storage-check]:checked');
  for (var i = 0; i < storageCheckboxes.length; i++) {
    selectedStorages.push(storageCheckboxes[i].value);
  }

  var signatureType = 'cms';
  var decode = false;
  var encapsulate = true;
  var digested = false;
  var localeRadio = 'ru';
  var tsaProfile = null;

  var signInfo = {
    module: 'kz.gov.pki.knca.basics',
    method: 'sign',
    args: {
      allowedStorages: selectedStorages,
      format: signatureType,
      data: signature,
      signingParams: {
        decode: decode,
        encapsulate: encapsulate,
        digested: digested,
        tsaProfile: tsaProfile,
      },
      signerParams: {
        extKeyUsageOids: [],
        chain: [],
      },
      locale: localeRadio,
    },
  };

  if (selectedStorages.length == 0) {
    delete signInfo.args.allowedStorages;
  }

  return connect()
    .then((webSocket) => {
      webSocket.send(JSON.stringify(signInfo));

      return new Promise((resolve, reject) => {
        webSocket.onmessage = async ({ data }) => {
          response = JSON.parse(data);
          if (response != null) {
            var responseStatus = response['status'];
            if (responseStatus === true) {
              var responseBody = response['body'];
              if (responseBody != null) {
                if (Object.prototype.hasOwnProperty.call(responseBody, 'result')) {
                  const signatureToSend = Array.isArray(responseBody.result)
                    ? responseBody.result.join('')
                    : responseBody.result;
                  try {
                    await axios.post(
                      url,
                      {
                        signature: signatureToSend, // Используем корректные данные
                        contract: contractId,
                      },
                      {
                        withCredentials: true,
                      },
                    );
                    console.log('Sign is successfully sent');
                    resolve(true); // Resolve the promise when the request is successful
                  } catch (error) {
                    console.error('Error in Axios request:', error);
                    reject(error); // Reject the promise on error
                  }
                }
              }
            } else if (responseStatus === false) {
              var responseCode = response['code'];
              alert(responseCode);
              reject(responseCode); // Reject the promise on signing failure
            }
          }
        };
      });
    })
    .catch(function (err) {
      unblockScreen();
      console.error('An error occurred:', err);
      return Promise.reject(err);
    });
}
/* global jQuery */

function blockScreen() {
  jQuery.blockUI({
    message:
      '<img src="js/loading.gif" /><br/>NCALayer-дің жауабын күте тұрыңыз<br/>Подождите, выполняется операция в NCALayer...',
    css: {
      border: 'none',
      padding: '15px',
      backgroundColor: '#000',
      '-webkit-border-radius': '10px',
      '-moz-border-radius': '10px',
      opacity: 0.5,
      color: '#fff',
    },
  });
}

function unblockScreen() {
  jQuery.unblockUI();
}

function openDialog() {
  if (
    confirm(
      'NCALayer-ге қосылғанда қате шықты. NCALayer-ды қайта қосып, ОК-ді басыңыз\nОшибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК',
    ) === true
  ) {
    location.reload();
  }
}

export { sign };
