import React from 'react';
import ReactPaginate from 'react-paginate';
import { StyledPagination } from './Pagination.style';

type PaginationProps = {
  pageCount: number;
  currentPage: number;
  onPageChange: (data: { selected: number }) => void;
};

const leftArrow = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='#1E2248'>
    <path d='M14.6667 7.66665V8.33332C14.6667 8.51741 14.5175 8.66665 14.3334 8.66665H3.55337L6.52004 11.6266C6.58315 11.6892 6.61864 11.7744 6.61864 11.8633C6.61864 11.9522 6.58315 12.0374 6.52004 12.1L6.04671 12.5667C5.98412 12.6298 5.89892 12.6652 5.81004 12.6652C5.72116 12.6652 5.63596 12.6298 5.57337 12.5667L1.48004 8.47998C1.38625 8.3863 1.33349 8.25921 1.33337 8.12665V7.87332C1.33491 7.74104 1.38745 7.61446 1.48004 7.51998L5.57337 3.43332C5.63596 3.37021 5.72116 3.33472 5.81004 3.33472C5.89892 3.33472 5.98412 3.37021 6.04671 3.43332L6.52004 3.90665C6.58275 3.96809 6.61809 4.05219 6.61809 4.13998C6.61809 4.22778 6.58275 4.31187 6.52004 4.37332L3.55337 7.33332H14.3334C14.5175 7.33332 14.6667 7.48255 14.6667 7.66665Z' />
  </svg>
);

const rightArrow = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='#1E2248'>
    <path d='M1.33331 8.33332V7.66665C1.33331 7.48255 1.48255 7.33332 1.66665 7.33332H12.4466L9.47998 4.37332C9.41688 4.31073 9.38138 4.22553 9.38138 4.13665C9.38138 4.04777 9.41688 3.96257 9.47998 3.89998L9.95331 3.43332C10.0159 3.37021 10.1011 3.33472 10.19 3.33472C10.2789 3.33472 10.3641 3.37021 10.4266 3.43332L14.52 7.51998C14.6138 7.61366 14.6665 7.74075 14.6666 7.87332V8.12665C14.6651 8.25893 14.6126 8.3855 14.52 8.47998L10.4266 12.5667C10.3641 12.6298 10.2789 12.6652 10.19 12.6652C10.1011 12.6652 10.0159 12.6298 9.95331 12.5667L9.47998 12.0933C9.41727 12.0319 9.38193 11.9478 9.38193 11.86C9.38193 11.7722 9.41727 11.6881 9.47998 11.6266L12.4466 8.66665H1.66665C1.48255 8.66665 1.33331 8.51741 1.33331 8.33332Z' />
  </svg>
);

export const Pagination: React.FC<PaginationProps> = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <StyledPagination>
      <ReactPaginate
        previousLabel={leftArrow}
        nextLabel={rightArrow}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => onPageChange({ selected })}
        forcePage={currentPage}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </StyledPagination>
  );
};
