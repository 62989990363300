import styled from 'styled-components';

const Tag = styled.div`
  color: var(--Tag-Cyan-text, #fefefe);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  border-radius: 24px;
  background: var(--Tag-Cyan-background, #007ea6);
  &.unimoney {
    color: var(--primary-50, var(--primary-50, #fefefe));
    background: var(--charts-base-04, #01c0b0);
  }
  &.smartcash {
    color: var(--primary-700, #021033);
    background: var(--charts-base-11, #fef600);
  }
  &.malafeyev {
    color: #021033; /* Gold text color */
    background: linear-gradient(45deg, #d4af37, #ffdd99, #ffb347);
    background-size: 200% 200%; /* Enhance the smoothness of gradient */
    animation: gradientAnimation 5s ease infinite; /* Optional animation */
    border-radius: 0px;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export { Tag };
