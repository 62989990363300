import { css, styled } from 'styled-components';
import { InputMask } from '@react-input/mask';

interface InputProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
}

const UserContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  background: var(--neutral-50, #fefefe);
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 460px;
  .col {
    flex-direction: column;
    align-items: baseline;
    gap: 0;
  }
  button {
    width: 100%;
  }
`;

const UserInfo = styled.div`
  font-size: 14px;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 460px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  color: #0d0d0d;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #5f5f5f;
  }
`;

const ContactBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 460px;
  button {
    height: 36px;
    width: 36px;
  }
`;

const Input = styled(InputMask)<InputProps>`
  margin-top: 10px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border: 1px solid var(--neutral-400, #a0a0a0);
      background: var(--neutral-200, #d8d8d8);
    `}

  &:focus {
    outline: none;
    border: 1px solid #5784f7;
    color: #292d32;
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  max-width: 560px;
`;

export const BoxAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const TitleAvatar = styled.p`
  color: #0d0d0d;
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const DescriptionAvatar = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const BoxAvatarImg = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
`;

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const Circle = styled.div<{ avatarFileName?: string }>`
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #e3e3e3;

  ${({ avatarFileName }) =>
    avatarFileName &&
    css`
      background-image: url(${BASE_URL}/images/avatar/${avatarFileName});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

export { UserContainer, UserInfo, Input, ContentBox, ContactBox };
