import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  CloseButton,
  Title,
  HeaderBox,
  ContentBox,
  BoxInput,
  MoreInfoBox,
  MoreInfo,
  ButtonBox,
  BoxSubmitted,
  BoxCheck,
} from './ApproveModal.style';
import { InputText } from 'components/common/Input/InputText';
import { NewButton } from 'components/common/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getMyAccounts } from 'components/api/bankAccount.api';
import { SelectSearch } from 'components/common/Input/SelectSearch';
import { useAppContext } from 'contexts/AppContext';
import { Checkbox } from 'components/common/Checkbox';
import { ErrorModal } from 'components/common/ErrorModal';
import IconClose from 'assets/img/IconClose.svg';

interface CreateApplicationProps {
  closeModal: () => void;
  amount?: string | number;
  period?: string | number;
  rate?: string | number;
}

interface Account {
  iban: string;
  id: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/`;

export const ApproveModal: React.FC<CreateApplicationProps> = ({
  closeModal,
  amount,
  period,
  rate,
}) => {
  const { id } = useParams();
  const initialLoanAmount = amount ? amount.toString() : '';
  const initialFinancingTerm = period ? period.toString() : '';
  const initialRemunerationRate = rate ? rate.toString() : '';
  const { userDataLight } = useAppContext();

  const formatNumber = (num: string) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const [loanAmount, setLoanAmount] = useState(initialLoanAmount);
  const [financingTerm, setFinancingTerm] = useState(initialFinancingTerm);
  const [startFinancing, setStartFinancing] = useState('');
  const [remunerationRate, setRemunerationRate] = useState(initialRemunerationRate);
  const [fine, setFine] = useState('');
  const [additionalConditions, setAdditionalConditions] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [formattedLoanAmount, setFormattedLoanAmount] = useState(formatNumber(initialLoanAmount));
  const [isLoading, setIsLoading] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isTypeContract, setIsTypeContract] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const offerData = {
    request: id,
    offerSum: loanAmount,
    offerPeriod: financingTerm,
    offerTransfer: startFinancing,
    offerRate: remunerationRate,
    offerPenaltyRate: fine,
    offerExtra: additionalConditions,
    account: selectedAccount ? selectedAccount.id : null,
    isInvestorIndividual: isIndividual,
    isGeneralBusinessAgreement: isTypeContract,
  };

  const sendOffer = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(`${BASE_URL}v1/cabinet/investor/request/offer`, offerData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setIsSubmitted(true);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (isIndividualCheckbox: boolean) => {
    setIsIndividual(isIndividualCheckbox);
  };

  const handleCheckboxContract = (isIndividualCheckboxContact: boolean) => {
    setIsTypeContract(isIndividualCheckboxContact);
  };

  const handleLoanAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Удаление всех нечисловых символов
    setLoanAmount(numericValue); // Обновление числового значения
    setFormattedLoanAmount(formatNumber(numericValue)); // Обновление отформатированного значения
  };

  const handleFinancingTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFinancingTerm(e.target.value);
  };

  const handleStartFinancingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartFinancing(e.target.value);
  };

  const handleRemunerationRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^(\d+)?(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setRemunerationRate(value);
    }
  };

  const handleFineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^(\d+)?(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setFine(value);
    }
  };

  const handleAdditionalConditionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalConditions(e.target.value);
  };

  useEffect(() => {
    getMyAccounts()
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleAccountChange = (selectedOption: { value: string; label: string }) => {
    const selectedAcc = accounts.find((account) => account.id.toString() === selectedOption.value);
    setSelectedAccount(selectedAcc || null);
  };

  const toggleSelectOpen = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const isFormValid = () => {
    return (
      loanAmount && financingTerm && startFinancing && remunerationRate && fine && selectedAccount
    );
  };

  return (
    <>
      {errorMessage && (
        <ErrorModal
          headerError='Ошибка'
          textError={errorMessage}
          buttonClose='Закрыть'
          onClick={() => setErrorMessage(null)}
        />
      )}
      {!errorMessage && (
        <Modal>
          <ModalContent>
            <HeaderBox>
              {isSubmitted ? <Title /> : <Title>Предложение</Title>}
              <CloseButton src={IconClose} alt='iconClose' onClick={closeModal} />
            </HeaderBox>
            <ContentBox>
              {isSubmitted ? (
                <BoxSubmitted>
                  <p>Ваше уникальное предложение отправлено!</p>
                  <div>
                    <NewButton
                      text='Окей'
                      onClick={() => {
                        closeModal();
                        window.location.reload();
                      }}
                    />
                  </div>
                </BoxSubmitted>
              ) : (
                <>
                  <InputText
                    title='Сумма моего предложения, тенге'
                    placeholder=''
                    type='text'
                    value={formattedLoanAmount}
                    onChange={handleLoanAmountChange}
                  />
                  <BoxInput>
                    <InputText
                      title='Срок финансирования (в днях)'
                      placeholder=''
                      type='number'
                      value={financingTerm}
                      onChange={handleFinancingTermChange}
                    />
                    <InputText
                      title='Начало финансирования (в днях)'
                      placeholder=''
                      type='number'
                      value={startFinancing}
                      onChange={handleStartFinancingChange}
                    />
                  </BoxInput>
                  <BoxInput>
                    <InputText
                      title='Ставка вознаграждения в месяц (%)'
                      placeholder=''
                      type='text'
                      value={remunerationRate}
                      onChange={handleRemunerationRateChange}
                    />
                    <InputText
                      title='Пеня за день (%)'
                      placeholder=''
                      type='text'
                      value={fine}
                      onChange={handleFineChange}
                    />
                  </BoxInput>
                  {userDataLight.organizationType !== 'LLC' && (
                    <MoreInfoBox>
                      <p>Тип инвестора</p>
                      <BoxCheck checked={isIndividual}>
                        <label onClick={() => handleCheckboxChange(true)}>
                          <Checkbox
                            checked={isIndividual}
                            onChange={() => handleCheckboxChange(true)}
                          />
                          <p>Физическое лицо</p>
                        </label>
                      </BoxCheck>
                      <BoxCheck checked={!isIndividual}>
                        <label onClick={() => handleCheckboxChange(false)}>
                          <Checkbox
                            checked={!isIndividual}
                            onChange={() => handleCheckboxChange(false)}
                          />
                          <p>Индивидуальный предприниматель (ИП)</p>
                        </label>
                      </BoxCheck>
                    </MoreInfoBox>
                  )}
                  <MoreInfoBox>
                    <p>Тип договора</p>
                    <BoxCheck checked={!isTypeContract}>
                      <label onClick={() => handleCheckboxContract(false)}>
                        <Checkbox
                          checked={!isTypeContract}
                          onChange={() => handleCheckboxContract(false)}
                        />
                        <p>Договор займа</p>
                      </label>
                    </BoxCheck>
                    <BoxCheck checked={isTypeContract}>
                      <label onClick={() => handleCheckboxContract(true)}>
                        <Checkbox
                          checked={isTypeContract}
                          onChange={() => handleCheckboxContract(true)}
                        />
                        <p>Договор о совместной деятельности</p>
                      </label>
                    </BoxCheck>
                  </MoreInfoBox>
                  <MoreInfoBox>
                    <p>Выберите счет</p>
                    <SelectSearch
                      options={accounts.map((account) => ({
                        value: account.id.toString(),
                        label: account.iban,
                      }))}
                      onOptionChange={handleAccountChange}
                      selectedValue={selectedAccount ? selectedAccount.iban : null}
                      isOpen={isSelectOpen}
                      onToggleOpen={toggleSelectOpen}
                    />
                  </MoreInfoBox>
                  <MoreInfoBox>
                    <p>Дополнительные условия</p>
                    <MoreInfo
                      placeholder='Опишите свои дополнительные условия'
                      value={additionalConditions}
                      onChange={handleAdditionalConditionsChange}
                    />
                  </MoreInfoBox>
                  <ButtonBox>
                    <NewButton
                      text={isLoading ? 'Отправка...' : 'Отправить'}
                      onClick={sendOffer}
                      disabled={!isFormValid() || isLoading}
                    />
                  </ButtonBox>
                </>
              )}
            </ContentBox>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
