import React from 'react';
import styled from 'styled-components';

interface HintProps {
  text: string; // Полный текст для тултипа
  displayText?: string; // Основной текст, отображаемый всегда
  truncatedLength?: number; // Длина сокращения текста
}

export const Hint: React.FC<HintProps> = ({ text, displayText, truncatedLength = 20 }) => {
  const isTruncated = text.length > truncatedLength;
  const displayHintText =
    displayText || (isTruncated ? text.substring(0, truncatedLength) + '...' : text);

  return (
    <HintContainer>
      <HintText>
        {displayHintText}
        {isTruncated && <Tooltip>{text}</Tooltip>}
      </HintText>
    </HintContainer>
  );
};

const HintContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const HintText = styled.span`
  display: inline-block;
  position: relative;
  &:hover > span {
    visibility: visible;
    opacity: 1;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  background-color: var(--primary-50, #dfe8fd);
  color: var(--primary-400, #0f62fe);
  text-align: center;
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
  position: absolute;
  z-index: 1000;
  white-space: nowrap;

  top: 150%;
  left: 50%;
  transform: translateX(-50%);

  transition: opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%; /* Переместим стрелку наверх */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary-50, #dfe8fd) transparent;
  }
`;

export default Hint;
