import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const FileLink = styled.a`
  width: 100%;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  background: #f4f4f4;
  color: #161616;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  cursor: pointer;
  div {
    display: flex;
    gap: 16px;
    width: 50%;
    img {
      width: 16px;
      height: 16px;
    }
  }

  p {
    color: var(--Link-link-primary, #0f62fe);
    font-family: 'Jost';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
`;

export const NoDocumentsMessage = styled.div`
  color: red;
`;
