import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  CustomSelectWrapper,
  CustomSelect,
  CustomOptions,
  CustomOption,
  ButtonBoxDowland,
} from './Payments.style';
import {
  Container,
  StyledLink,
  BoxHeader,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
  BoxInputDescription,
  BoxInfoInput,
  StyledDatePicker,
  DatePickerWrapper,
  CalendarIcon,
  BoxInput,
  ButtonsBox,
  ActionFilterBox,
  Title,
  ControlBox,
  FilterBox,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button';
import { Checkbox } from 'components/common/Checkbox';
import SelectBox from 'components/common/SelectBox/SelectBox';
import { Pagination } from 'components/Pagination';
import { PaymentData } from './Payments.interface';
import 'react-datepicker/dist/react-datepicker.css';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { InputSearch } from 'components/common/Input/InputSearch';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { ru } from 'date-fns/locale';
import { formatNumberWithSpaces, removeSpacesFromNumber } from 'utils/numberFormat';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';
import Hint from 'components/common/Hint/Hint';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

export const Payments = () => {
  const { userDataLight } = useAppContext();
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const [selectedPayments, setSelectedPayments] = useState<Set<number>>(new Set());
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingOneC, setIsLoadingOneC] = useState(false);
  const [openSelectId, setOpenSelectId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<{
    dateFrom: string | null;
    dateTo: string | null;
    contractNumber: string;
    paymentType: string[];
    debitMin: string;
    debitMax: string;
    creditMin: string;
    creditMax: string;
    balanceMin: string;
    balanceMax: string;
    status: string[];
    showPlan: boolean | null;
  }>({
    dateFrom: null,
    dateTo: null,
    contractNumber: '',
    paymentType: [],
    debitMin: '',
    debitMax: '',
    creditMin: '',
    creditMax: '',
    balanceMin: '',
    balanceMax: '',
    status: [],
    showPlan: null,
  });

  // State for filters
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string[]>([]); // change from string to string[]
  const [status, setStatus] = useState<string[]>([]); // already array
  const [debitMin, setDebitMin] = useState('');
  const [debitMax, setDebitMax] = useState('');
  const [creditMin, setCreditMin] = useState('');
  const [creditMax, setCreditMax] = useState('');
  const [balanceMin, setBalanceMin] = useState('');
  const [balanceMax, setBalanceMax] = useState('');
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // If the click is outside of a dropdown (i.e., not inside a SelectBox)
      if (!target.closest('.dropdown-container')) {
        setOpenDropdownIndex(null); // Close all dropdowns
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const endpoint =
    userDataLight.organizationRole === 'INVESTOR'
      ? `api/v1/cabinet/investor/payment/calendar/`
      : `api/v1/cabinet/creditor/payment/calendar/`;

  const fetchPayments = useCallback(
    async (
      page = 0,
      filters: any = {},
      sort: { field: string; direction: 'asc' | 'desc' | null } = sortConfig,
    ) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        if (sort.field && sort.direction) {
          params.append('sort', `${sort.field},${sort.direction}`);
        }

        const url = `${BASE_URL}${endpoint}find_all?${params.toString()}`;
        const response = await axios.post(url, filters, { withCredentials: true });

        setPayments(response.data.content || []);
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка при загрузке данных о платежах:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [sortConfig, endpoint], // Добавьте зависимости, чтобы функция пересоздавалась только при изменении этих значений
  );

  useEffect(() => {
    fetchPayments(currentPage, filters, sortConfig);
  }, [currentPage, filters, sortConfig]);

  const handleSort = useCallback(
    (field: string) => {
      setSortConfig((prevSortConfig) => {
        const newDirection: 'asc' | 'desc' | null =
          prevSortConfig.field === field && prevSortConfig.direction === 'asc' ? 'desc' : 'asc';

        const newSortConfig = { field, direction: newDirection };

        // Вызов фильтрации и сортировки с текущими фильтрами и новой сортировкой
        fetchPayments(0, filters, newSortConfig);

        return newSortConfig;
      });
    },
    [filters, fetchPayments], // Добавьте фильтры и функцию fetchPayments как зависимости
  );

  const applyFiltersAndSorting = () => {
    const currentFilters = {
      dateFrom: dateFrom ? dateFrom.toISOString().split('T')[0] : null,
      dateTo: dateTo ? dateTo.toISOString().split('T')[0] : null,
      contractNumber: contractNumber || '',
      paymentType: paymentType.length ? paymentType : [],
      debitMin: debitMin,
      debitMax: debitMax,
      creditMin: creditMin,
      creditMax: creditMax,
      balanceMin: balanceMin,
      balanceMax: balanceMax,
      status: status.length ? status : [],
      showPlan: showPlan,
    };

    // Обновляем фильтры и вызываем данные только один раз
    setFilters(currentFilters);
    setCurrentPage(0); // Сброс на первую страницу
  };

  const clearFilters = () => {
    setDateFrom(null);
    setDateTo(null);
    setContractNumber('');
    setPaymentType([]);
    setDebitMin('');
    setDebitMax('');
    setCreditMin('');
    setCreditMax('');
    setBalanceMin('');
    setBalanceMax('');
    setStatus([]);
    setShowPlan(false);
    setCurrentPage(0);
    fetchPayments(0, {}, sortConfig);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    fetchPayments(selected, filters, sortConfig); // Передаем текущие фильтры и сортировку
  };

  const handlePaymentSelection = (paymentId: number) => {
    setSelectedPayments((prev) => {
      const updated = new Set(prev);
      if (updated.has(paymentId)) {
        updated.delete(paymentId);
      } else {
        updated.add(paymentId);
      }
      return updated;
    });
  };

  const handleGenerateInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    const url = `${BASE_URL}${endpoint}payment_ordinary`;
    setIsLoadingPdf(true);
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice.zip');
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating invoice:', error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handleGenerateOneCInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    const url = `${BASE_URL}${endpoint}export_onec`;
    setIsLoadingOneC(true);
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice_onec.txt');
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating 1C invoice:', error);
    } finally {
      setIsLoadingOneC(false);
    }
  };

  const handleStatusChange = async (paymentCalendarId: number, status: string) => {
    const url = `${BASE_URL}${endpoint}payment_calendar/status/${paymentCalendarId}?paymentStatus=${status}`;
    try {
      await axios.get(url, { withCredentials: true });
      const updatedPayments = payments.map((payment) =>
        payment.paymentCalendarId === paymentCalendarId
          ? { ...payment, paymentStatus: status }
          : payment,
      );
      setPayments(updatedPayments);
      setOpenSelectId(null); // Close the select after status change
    } catch (error) {
      console.error('Ошибка при обновлении статуса:', error);
    }
  };

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'PAID':
        return 'paid';
      case 'UNPAID':
        return 'unpaid';
      case 'AWAITING_PAYMENT':
        return 'awaiting';
      default:
        return '';
    }
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const paymentTypeOptions = [
    { id: 'INTEREST', name: 'Процент' },
    { id: 'BODY', name: 'Тело' },
  ];

  const statusOptions = [
    { id: 'PAID', name: 'Оплачено' },
    { id: 'UNPAID', name: 'Не оплачено' },
    { id: 'AWAITING_PAYMENT', name: 'Ожидание' },
  ];

  const selectedPaymentTypeNamesArray = paymentTypeOptions
    .filter((option) => paymentType.includes(option.id))
    .map((option) => option.name);

  const selectedStatusNamesArray = statusOptions
    .filter((option) => status.includes(option.id))
    .map((option) => option.name);

  return (
    <Container>
      <Title>Мои платежи</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск платежей'
            name='searchQuery'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                applyFiltersAndSorting();
              }
            }}
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='Фильтр' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      <ActionFilterBox isOpen={isFilterBoxOpen}>
        <BoxInputDescription>
          <BoxInput>
            <p>Дата</p>
            <BoxInfoInput>
              <span>От</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={dateFrom}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setDateFrom(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
              <span>До</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={dateTo}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setDateTo(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Доп. фильтры</p>
            <BoxInfoInput>
              <Checkbox checked={showPlan} onChange={() => setShowPlan((prev) => !prev)} />
              <span>Платежи по договорам на стадии подписания </span>
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <p>Приход</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                value={formatNumberWithSpaces(debitMin)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setDebitMin(rawValue);
                }}
                placeholder='100 000'
                maxWidth='100px'
              />
              <span>До</span>
              <InputNew
                value={formatNumberWithSpaces(debitMax)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setDebitMax(rawValue);
                }}
                placeholder='900 000'
                maxWidth='100px'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Расход</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                value={formatNumberWithSpaces(creditMin)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setCreditMin(rawValue);
                }}
                placeholder='100 000'
                maxWidth='100px'
              />
              <span>До</span>
              <InputNew
                value={formatNumberWithSpaces(creditMax)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setCreditMax(rawValue);
                }}
                placeholder='900 000'
                maxWidth='100px'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Сальдо</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                value={formatNumberWithSpaces(balanceMin)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setBalanceMin(rawValue);
                }}
                placeholder='100 000'
              />
              <span>До</span>
              <InputNew
                value={formatNumberWithSpaces(balanceMax)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setBalanceMax(rawValue);
                }}
                placeholder='900 000'
              />
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <SelectBox
              label='Тип операции'
              options={paymentTypeOptions} // Pass the payment type options
              selectedOptions={selectedPaymentTypeNamesArray} // Pass the array of selected names
              onOptionSelect={(option) =>
                setPaymentType(
                  (prev) =>
                    prev.includes(option.id)
                      ? prev.filter((id) => id !== option.id) // Deselect
                      : [...prev, option.id], // Select
                )
              }
              isDropdownOpen={openDropdownIndex === 0}
              toggleDropdown={() => toggleDropdown(0)}
            />
          </BoxInput>

          <BoxInput>
            <SelectBox
              label='Статус'
              options={statusOptions} // Pass the status options
              selectedOptions={selectedStatusNamesArray} // Pass the array of selected names
              onOptionSelect={(option) =>
                setStatus(
                  (prev) =>
                    prev.includes(option.id)
                      ? prev.filter((id) => id !== option.id) // Deselect
                      : [...prev, option.id], // Select
                )
              }
              isDropdownOpen={openDropdownIndex === 1}
              toggleDropdown={() => toggleDropdown(1)}
            />
          </BoxInput>
        </BoxInputDescription>

        <ButtonsBox>
          <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
          <NewButton text='Применить фильтры' className='small' onClick={applyFiltersAndSorting} />
        </ButtonsBox>
      </ActionFilterBox>
      <ButtonBoxDowland>
        <NewButton
          text={isLoadingPdf ? 'Загрузка...' : 'Сгенерировать счет .pdf'}
          loading={isLoadingPdf}
          onClick={handleGenerateInvoice}
          disabled={selectedPayments.size === 0 || isLoadingOneC} // Disable button if loading, no selections, or other button is loading
        />
        <NewButton
          text={isLoadingOneC ? 'Загрузка...' : 'Выгрузка в формате 1С'}
          loading={isLoadingOneC}
          onClick={handleGenerateOneCInvoice}
          disabled={selectedPayments.size === 0 || isLoadingPdf} // Disable button if loading, no selections, or other button is loading
        />
      </ButtonBoxDowland>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <Table>
            <thead>
              <TableRowHeader>
                <TableHeader width='40px'>
                  <Checkbox
                    checked={false}
                    onChange={() => {
                      // Logic to handle select all payments
                    }}
                  />
                </TableHeader>
                <TableHeader
                  width='120px'
                  isSorted={sortConfig.field === 'paymentDate'}
                  sortable={true}
                  direction={sortConfig.field === 'paymentDate' ? sortConfig.direction : null}
                  onClick={() => handleSort('paymentDate')}>
                  <Hint displayText='Дата' text='Дата проведения операции' />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'contractNum'}
                  sortable={true}
                  direction={sortConfig.field === 'contractNum' ? sortConfig.direction : null}
                  onClick={() => handleSort('contractNum')}>
                  <Hint
                    displayText='Номер контракта'
                    text='Уникальный номер контракта, связанного с данным платежом'
                  />
                </TableHeader>
                <TableHeader sortable={false}>
                  <Hint displayText='Тип операции' text='Тип финансовой операции' />
                </TableHeader>
                <TableHeader sortable={false} className='right'>
                  <Hint displayText='Приход' text='Сумма поступления средств' />
                </TableHeader>
                <TableHeader sortable={false} className='right'>
                  <Hint displayText='Расход' text='Сумма списания средств' />
                </TableHeader>
                <TableHeader sortable={false} className='right'>
                  <Hint displayText='Сальдо' text='Текущее сальдо счета после операции' />
                </TableHeader>
                <TableHeader sortable={false} className=''>
                  <Hint displayText='Статус' text='Текущий статус платежной операции' />
                </TableHeader>
              </TableRowHeader>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <TableRow key={uuidv4()}>
                  <TableCell>
                    <Checkbox
                      checked={selectedPayments.has(payment.paymentCalendarId)}
                      onChange={() => handlePaymentSelection(payment.paymentCalendarId)}
                    />
                  </TableCell>
                  <TableCell>{payment.paymentDate}</TableCell>
                  <TableCell>
                    <StyledLink to={`/contracts/${payment.contractId}`}>
                      {payment.contractNum}
                    </StyledLink>
                  </TableCell>
                  <TableCell>{payment.paymentTypeName}</TableCell>
                  <TableCell className='right'>
                    {payment.debit.toLocaleString('ru-KZ', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    ₸
                  </TableCell>
                  <TableCell className='right'>
                    {payment.credit.toLocaleString('ru-KZ', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    ₸
                  </TableCell>
                  <TableCell
                    className='right'
                    style={{ color: payment.balance > 0 ? '#5784F7' : 'red' }}>
                    {payment.balance > 0 ? '+ ' : ''}
                    {payment.balance.toLocaleString('ru-KZ', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    ₸
                  </TableCell>
                  <TableCell className='paymets'>
                    <CustomSelectWrapper>
                      <CustomSelect
                        className={`${getStatusClassName(payment.paymentStatus)} ${
                          openSelectId === payment.paymentCalendarId ? 'open' : ''
                        }`}
                        onClick={() =>
                          setOpenSelectId(
                            openSelectId === payment.paymentCalendarId
                              ? null
                              : payment.paymentCalendarId,
                          )
                        }>
                        {payment.paymentStatus === 'PAID'
                          ? 'Оплачено'
                          : payment.paymentStatus === 'UNPAID'
                            ? 'Не оплачено'
                            : 'Ожидание'}
                        <span>▾</span>
                      </CustomSelect>
                      {openSelectId === payment.paymentCalendarId && (
                        <CustomOptions>
                          <CustomOption
                            className='paid'
                            onClick={() => handleStatusChange(payment.paymentCalendarId, 'PAID')}>
                            Оплачено
                          </CustomOption>
                          <CustomOption
                            className='awaiting'
                            onClick={() =>
                              handleStatusChange(payment.paymentCalendarId, 'AWAITING_PAYMENT')
                            }>
                            Ожидание
                          </CustomOption>
                          <CustomOption
                            className='unpaid'
                            onClick={() => handleStatusChange(payment.paymentCalendarId, 'UNPAID')}>
                            Не оплачено
                          </CustomOption>
                        </CustomOptions>
                      )}
                    </CustomSelectWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>

          <Pagination
            pageCount={paginationInfo.totalPages}
            currentPage={paginationInfo.currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};
