import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BreadCrumbsBox, Container } from 'components/RequestDetailsDraft/RequestDetails.style';
import { HeaderBox } from './SuggestionDetails.style';
import IconRedTimer from 'assets/img/IconRedTimer.svg';
import { TagTime, Timer } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { TimeLeft } from 'components/RequestDetailsDraft/RequestDetailsDraft.interface';
import {
  Table,
  TableHeader,
  TableCell,
  TableCellHeader,
  Backdrop,
  Modal,
  ModalHeader,
  ModalActions,
  Button,
} from 'components/OffersBusinessList/CompareOffers.style';
import { NewButton } from 'components/common/Button';
import { OfferDetails } from './SuggestionsInvestorList.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const SuggestionDetails = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [offerDetails, setOfferDetails] = useState<OfferDetails | null>(null);
  const [remainingTime, setRemainingTime] = useState('');
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/v1/cabinet/investor/request/offer/get/${offerId}`,
          {
            withCredentials: true,
          },
        );
        setOfferDetails(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных оффера:', error);
      }
    };

    fetchOfferDetails();
  }, [offerId]);

  const calculateTimeLeft = (): TimeLeft => {
    if (!offerDetails?.requestDtEnd) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const endTime = new Date(offerDetails.requestDtEnd).getTime();
    const now = new Date().getTime();
    const difference = endTime - now;

    if (difference > 0) {
      return {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return { hours: 0, minutes: 0, seconds: 0 };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      const formattedTime = `${timeLeft.hours.toString().padStart(2, '0')}:${timeLeft.minutes
        .toString()
        .padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`;
      setRemainingTime(formattedTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [offerDetails]);

  if (!offerDetails) {
    return <div>Загрузка...</div>;
  }

  const LinkSuggestionList = () => {
    navigate('/suggestions');
  };

  const rejectUrl = `${BASE_URL}api/v1/cabinet/investor/request/offer/reject/${offerDetails.id}`;

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  const confirmReject = () => {
    setRejectModalOpen(false);
    axios
      .get(rejectUrl, {
        withCredentials: true,
      })
      .then((response) => {
        window.location.href = '/suggestions';
      })
      .catch((error) => {
        console.error('Error rejecting the offer', error);
      });
  };

  return (
    <Container>
      <BreadCrumbsBox>
        <p>
          <a href='/suggestions'>Предложения по заявкам</a>
        </p>
        <p>{'>'}</p>
        <p className='select'>Предложение на заявку {offerDetails.requestNum}</p>
      </BreadCrumbsBox>
      <HeaderBox>
        <div className='leftBox'>
          <div>
            <h2>Предложение по</h2>
            <h2>
              <Link
                style={{ color: '#01AEB7', textDecoration: 'none' }}
                to={`/request/${offerDetails.request}`}>
                заявке {offerDetails.requestNum}
              </Link>
            </h2>
          </div>
          <TagTime>
            <img src={IconRedTimer} alt='timer' />
            <Timer>{remainingTime || '00:00:00'}</Timer>
          </TagTime>
        </div>
        <span onClick={LinkSuggestionList}>К другим предложениям</span>
      </HeaderBox>
      <Table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <TableHeader>Ваше предложение</TableHeader>
            <TableHeader>Заявка</TableHeader>
          </tr>
        </thead>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Сумма займа:</TableCellHeader>
            <TableCell>{offerDetails.offerSum.toLocaleString('ru-KZ')} тенге</TableCell>
            <TableCell>
              {offerDetails.requestInfo.requestedAmount.toLocaleString('ru-KZ')} тенге
            </TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Срок финансирования:</TableCellHeader>
            <TableCell>{offerDetails.offerPeriod} дней</TableCell>
            <TableCell>{offerDetails.requestInfo.requestPeriod} дней</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Срок начала финансирования:</TableCellHeader>
            <TableCell>{offerDetails.offerTransfer} дней</TableCell>
            <TableCell>—</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Ставка вознаграждения:</TableCellHeader>
            <TableCell>{offerDetails.offerRate} %</TableCell>
            <TableCell>—</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Пеня за день:</TableCellHeader>
            <TableCell>{offerDetails.offerPenaltyRate} %</TableCell>
            <TableCell>—</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Сумма вознаграждения:</TableCellHeader>
            <TableCell>{offerDetails.overpaymentSum.toLocaleString('ru-KZ')} тенге</TableCell>
            <TableCell>—</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Дополнительные условия:</TableCellHeader>
            <TableCell>{offerDetails.offerExtra}</TableCell>
            <TableCell>—</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader></TableCellHeader>
            <TableCell>
              {offerDetails.statusName === 'Принят' ? (
                <p style={{ color: 'green' }}>Принят</p>
              ) : offerDetails.statusName !== 'Отменен заёмщиком' &&
                offerDetails.statusName !== 'Отменён инвестором' ? (
                <div>
                  <NewButton onClick={handleReject} text='Отозвать предложение' className='red' />
                </div>
              ) : (
                <p style={{ color: 'red' }}>Отменена</p>
              )}
            </TableCell>

            <TableCell></TableCell>
          </tr>
        </tbody>
      </Table>
      {isRejectModalOpen && (
        <Backdrop>
          <Modal>
            <ModalHeader>Вы уверены, что хотите отказаться от предложения?</ModalHeader>
            <p>Действия невозвратное, Вы не сможете вернуться к данному предложению.</p>
            <ModalActions>
              <NewButton onClick={confirmReject} text='Отозвать' className='red' />
              <Button className='cancel' onClick={() => setRejectModalOpen(false)}>
                Отменить
              </Button>
            </ModalActions>
          </Modal>
        </Backdrop>
      )}
    </Container>
  );
};

export default SuggestionDetails;
