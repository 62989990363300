import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as IconClose } from 'assets/img/IconClose.svg';

interface IconDotProps {
  iconType: 'WARNING' | 'INFO' | 'SYSTEM';
}

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

// Define the animation for sliding up (closing)
const slideUp = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

// Use the isOpen prop to determine which animation to apply
const Dropdown = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 64px;
  right: -98px;
  background: white;
  max-height: 600px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 508px;
  z-index: 100;
  overflow-x: scroll;
  animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.5s ease-in-out;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.5s ease-in-out;
`;

const NotificationItem = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--Primary-Control-Grey, #d9d9d9);
  background: #fafafa;
  &:last-child {
    border-bottom: none;
  }
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Primary-Control-Grey, #d9d9d9);
  color: #292929;

  font-size: 20px;
  font-weight: 600;
  line-height: 44px;
`;

const NotificationClose = styled(IconClose)`
  cursor: pointer;
  width: 28px;
  height: 28px;
`;

const NotificationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NotificationTime = styled.span`
  color: var(--Control, #9aa4b0);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const LoadingIndicator = styled.img`
  width: 60px;
  height: 60px;
  margin: 20px auto;
`;

const IconDot = styled.span<IconDotProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  background-color: ${(props) => {
    switch (props.iconType) {
      case 'WARNING':
        return '#FF5353';
      case 'INFO':
        return '#F8B801';
      case 'SYSTEM':
        return '#5784F7';
      default:
        return 'transparent'; // This will handle the generic string case
    }
  }};
`;

const NotificationInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  p {
    color: var(--Black-Main, #292d32);
    max-width: 396px;
    font-size: 15px;
    font-weight: 400;
    line-height: 26.5px; /* 147.222% */
  }
`;

export const NotificationLinkItem = styled.a`
  padding: 24px;
  border-bottom: 1px solid var(--Primary-Control-Grey, #d9d9d9);
  background: #fafafa;
  &:last-child {
    border-bottom: none;
  }
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    background-color: var(--Highlight-Primary-Hover, #e0f7fa);
    p {
      color: #5784f7; /* This will change the text color to blue on hover */
    }
  }
`;

export {
  Dropdown,
  NotificationItem,
  NotificationHeader,
  NotificationClose,
  NotificationBox,
  NotificationTime,
  LoadingIndicator,
  IconDot,
  NotificationInfo,
};
