import React, { useState } from 'react';
import { Container } from './TwoStep.style';
import { Box, BoxContent, BoxButtons } from './FourStep.style';
import AproveGIF from 'assets/img/AproveGIF.gif';
import { NewButton } from 'components/common/Button';
import { FourStepProps } from './NewCreateApplication.interface';
import axios from 'axios';
import { ErrorModal } from 'components/common/ErrorModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const FourStep: React.FC<FourStepProps> = ({ idApplication }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const publishPost = (id: any) => {
    setIsLoading(true); // Устанавливаем состояние загрузки
    const url = `${BASE_URL}api/v1/cabinet/creditor/request/run/${id}`;

    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        window.location.href = `/req/${id}`;
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('An error occurred while publishing the post. Please try again.');
      })
      .finally(() => {
        setIsLoading(false); // Сбрасываем состояние загрузки после завершения
      });
  };

  const closeModal = () => {
    setError(null);
  };

  return (
    <Container>
      <Box>
        <img src={AproveGIF} alt='IconDone' />
        <BoxContent>
          <h3>Успех!</h3>
          <p>Заявка на финансирование создана</p>
          <BoxButtons>
            <NewButton
              text='Посмотреть черновик'
              className='white'
              onClick={() => {
                window.location.href = `/req/${idApplication}`;
              }}
            />
          </BoxButtons>
        </BoxContent>
      </Box>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default FourStep;
