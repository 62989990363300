import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'contexts/AppContext';
import {
  SidebarContainer,
  SidebarHeader,
  SidebarMenu,
  StyledNavLink,
  Line,
  ExtraTitle,
  LogoMenu,
  LogoCompany,
  IconDashboard,
  IconOrganization,
  IconApplications,
  IconDocuments,
  IconPayments,
  IconSettings,
  IconHelps,
  IconExit,
  StyledIconExit,
  IconBiling,
  IconInvestorSettings,
  IconMySuggestions,
  IconAdmin,
  IconGroup,
} from './SideBar.style';
import {
  DASHBOARD_URL,
  ORGANIZATION_URL,
  INVESTOR_SETTINGS_URL,
  APPLICATIONS_URL,
  CONTRACTS_URL,
  PAYMENTS_URL,
  BILLING_URL,
  SETTINGS_URL,
  HELPS_URL,
  ADMIN_URL,
  SUGGESTIONS_URL,
  GROUP_URL,
} from 'constants/urls';
import { default as IconMenu } from 'assets/img/IconMenu.svg';
import { default as Logo } from 'assets/img/Logo.svg';
import { logOut } from 'components/api/logOut.api';

export const SideBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const { userDataLight } = useAppContext();
  const toggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  if (!userDataLight) return null;

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <SidebarContainer $isOpen={isOpen}>
      <SidebarHeader>
        <LogoMenu src={IconMenu} alt='IconMenu' onClick={toggleIsOpen} />
        {isOpen && <LogoCompany src={Logo} alt='logo' />}
      </SidebarHeader>
      <SidebarMenu $isOpen={isOpen}>
        {/* <StyledNavLink to={DASHBOARD_URL}>
          <IconDashboard />
          {isOpen && t('sidebar.dashboard')}
        </StyledNavLink> */}
        <StyledNavLink to={APPLICATIONS_URL}>
          <IconApplications />
          {isOpen &&
            (userDataLight?.organizationRole !== 'BUSINESS' ? 'Сделки' : t('sidebar.applications'))}
        </StyledNavLink>
        {userDataLight?.organizationRole !== 'BUSINESS' && (
          <StyledNavLink to={SUGGESTIONS_URL}>
            <IconMySuggestions />
            {isOpen && t('sidebar.mySuggestions')}
          </StyledNavLink>
        )}
        <StyledNavLink to={CONTRACTS_URL}>
          <IconDocuments />
          {isOpen && t('sidebar.documents')}
        </StyledNavLink>
        <StyledNavLink to={PAYMENTS_URL}>
          <IconPayments />
          {isOpen && t('sidebar.payments')}
        </StyledNavLink>
        {/* {orgData?.organizationRole !== 'BUSINESS' && (
          <StyledNavLink to={BILLING_URL}>
            <IconBiling />
            {isOpen && t('sidebar.billing')}
          </StyledNavLink>
        )} */}
        <StyledNavLink to={GROUP_URL}>
          <IconGroup />
          {isOpen && 'Мои группы'}
        </StyledNavLink>
        <StyledNavLink to={ORGANIZATION_URL}>
          <IconOrganization />
          {isOpen && t('sidebar.organization')}
        </StyledNavLink>
        {/* {userDataLight?.organizationRole !== 'BUSINESS' && (
          <StyledNavLink to={INVESTOR_SETTINGS_URL}>
            <IconInvestorSettings />
            {isOpen && t('sidebar.investorSettings')}
          </StyledNavLink>
        )} */}
        <Line $isOpen={isOpen} />
        <ExtraTitle $isOpen={isOpen}>{isOpen && 'Дополнительно'}</ExtraTitle>{' '}
        {/* <StyledNavLink to={SETTINGS_URL}>
          <IconSettings />
          {isOpen && t('sidebar.settings')}
        </StyledNavLink> */}
        {/* <StyledNavLink to={HELPS_URL}>
          <IconHelps />
          {isOpen && t('sidebar.help')}
        </StyledNavLink> */}
        {userDataLight?.roles && userDataLight.roles.includes('ADMIN') && (
          <StyledNavLink to={ADMIN_URL}>
            <IconAdmin />
            {isOpen && t('sidebar.administration')}
          </StyledNavLink>
        )}
        <StyledIconExit to='/login' $isOpen={isOpen} onClick={handleLogout}>
          <IconExit />
          {isOpen && 'Выйти'}
        </StyledIconExit>
      </SidebarMenu>
    </SidebarContainer>
  );
};
