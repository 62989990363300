import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SettingDocumentIcon from 'assets/img/SettingDocumentIcon.svg';
import GroupIconAccept from 'assets/img/GroupIconAccept.svg';
import GroupIconDecline from 'assets/img/GroupIconDecline.svg';
import {
  Banner,
  BoxInfo,
  BoxInfoDescription,
  BoxInfoLeft,
  BoxInfoRight,
  BreadCrumbsBox,
  BrokerBox,
  Container,
  ContainerDescription,
  Description,
  DocumentAndBrokerBlock,
  DocumentBox,
  Fee,
  Logo,
  MainBox,
  Participants,
  SiteLink,
  TagRole,
  Title,
  InviteBox,
  ButtonBox,
  ControlGroups,
  SettingGroups,
  SettingGroupsMainBox,
  SettingGroupsMainBoxLeft,
  SettingGroupsMainBoxRight,
  Circle,
  SettingDocumentsBox,
  SettingDocumentsMain,
  SettingDocument,
  SettingButtonChange,
  SettingDocumentBox,
  AddNewDocumentBox,
  SettingButtonBox,
} from './GroupDetail.style';
import { NewButton } from 'components/common/Button';
import { GroupDetailInterface, GroupMember } from './GroupDetail.interface';
import { SwitcherBox, Tab } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { InputNew } from 'components/common/Input/InputNew/InputNew'; // Your input component
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { StatusCircle, StatusText } from '../Groups.style';
import { Checkbox } from 'components/common/Checkbox';
import { TextArea } from 'components/common/TextArea/TextArea';
import SuccessModal from 'components/common/SuccessModal/SuccessModal';
import { ErrorModal } from 'components/common/ErrorModal/ErrorModal';
import { useFileDownload } from 'utils/hooks';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const GroupDetail = () => {
  const { downloadFile } = useFileDownload(); // используем хук
  const { groupId } = useParams();
  const [groupDetail, setGroupDetail] = useState<GroupDetailInterface | null>(null);
  const [groupData, setGroupData] = useState<GroupMember[]>([]);
  const [activeTab, setActiveTab] = useState('controlGroups');
  const [iinValue, setIinValue] = useState('');
  const [iinError, setIinError] = useState<string | null>(null);
  const [selectedParticipants, setSelectedParticipants] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [refLink, setRefLink] = useState('');
  const [description, setDescription] = useState('');
  const [fee, setFee] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [initialGroupName, setInitialGroupName] = useState('');
  const [initialCompanyWebsite, setInitialCompanyWebsite] = useState('');
  const [initialDescription, setInitialDescription] = useState('');
  const [initialFee, setInitialFee] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Reference for file input
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false); // State for showing modal
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Message for modal
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false); // State for showing error modal
  const [errorHeader, setErrorHeader] = useState<string | null>(null); // Error modal header
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error modal message
  const [isSendingInvitation, setIsSendingInvitation] = useState(false); // New state for loading
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    axios
      .get<GroupDetailInterface>(`${BASE_URL}/api/v1/ui/group/${groupId}`, {
        withCredentials: true,
      })
      .then((response) => {
        const groupData = response.data;
        setGroupDetail(groupData);
        setGroupName(groupData.name);
        setCompanyWebsite(groupData.baseUrl);
        setRefLink(groupData.refLink || '');
        setDescription(groupData.description);
        setFee(groupData.fee || '');

        setInitialGroupName(groupData.name);
        setInitialCompanyWebsite(groupData.baseUrl);
        setInitialDescription(groupData.description);
        setInitialFee(groupData.fee || '');
      })
      .catch(() => {
        handleError('Ошибка загрузки данных', 'Не удалось загрузить данные группы.');
      });
  }, [groupId]);

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/api/v1/ui/group/${groupId}/participants`, {
        withCredentials: true,
      })
      .then((response) => {
        setGroupData(response.data);
      })
      .catch(() => {
        handleError('Ошибка загрузки участников', 'Не удалось загрузить участников.');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleIinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 12) {
      setIinValue(value);
      setIinError(value.length < 12 ? 'ИИН/БИН должен содержать 12 цифр' : null);
    }
  };

  const handleSendInvitation = () => {
    if (iinValue.length !== 12) {
      setIinError('ИИН/БИН должен содержать 12 цифр');
      return;
    }

    setIsSendingInvitation(true); // Set loading state

    axios
      .get(`${BASE_URL}/api/v1/ui/group/${groupId}/${iinValue}`, { withCredentials: true })
      .then(() => {
        setSuccessMessage('Приглашение успешно отправлено!');
        setIsSuccessModalVisible(true);
        fetchData(); // Refresh the table after sending the invitation
      })
      .catch(() => {
        handleError('Ошибка отправки приглашения', 'Не удалось отправить приглашение.');
      })
      .finally(() => {
        setIsSendingInvitation(false); // Remove loading state after the request
      });
  };

  const handleGroupAction = (accept: boolean) => {
    const actionText = accept ? 'приняли' : 'покинули';
    const successMessage = `Вы успешно ${actionText} группу`;

    axios
      .post(`${BASE_URL}/api/v1/ui/group/${groupId}/accept?accept=${accept}`, null, {
        withCredentials: true,
      })
      .then(() => {
        setSuccessMessage(successMessage);
        setIsSuccessModalVisible(true);

        setTimeout(() => {
          window.location.href = '/groups';
        }, 2500);
      })
      .catch(() => {
        const errorMessage = accept
          ? 'Не удалось принять приглашение'
          : 'Не удалось покинуть группу';
        handleError('Ошибка', errorMessage);
      });
  };

  const handleSaveClick = () => {
    setIsSaving(true);
    const updatedData = {
      ...groupDetail,
      name: groupName,
      baseUrl: companyWebsite,
      description: description,
      fee: fee,
    };

    axios
      .post(`${BASE_URL}/api/v1/ui/group/${groupId}`, updatedData, {
        withCredentials: true,
      })
      .then(() => {
        setIsSaving(false);
        setIsEditing(false);
      })
      .catch(() => {
        handleError('Ошибка сохранения', 'Не удалось сохранить изменения.');
        setIsSaving(false);
      });
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const handleFileUpload = (file: File) => {
    setIsUploading(true); // Start uploading
    const formData = new FormData();
    formData.append('file', file);

    axios
      .post(`${BASE_URL}/api/v1/ui/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then((response) => {
        const fileUid = response.data.uid;
        const fileName = file.name;
        handleSaveWithFileUid(fileUid, fileName); // Save the new file's UID and name to the group
      })
      .catch(() => {
        handleError('Ошибка загрузки файла', 'Не удалось загрузить файл.');
        setIsUploading(false); // Stop uploading in case of error
      });
  };

  const handleSaveWithFileUid = (fileUid: string, fileName: string) => {
    setIsSaving(true);
    const updatedData = {
      ...groupDetail,
      file: {
        uid: fileUid,
        title: fileName, // Include file name in the payload
      },
    };

    axios
      .post(`${BASE_URL}/api/v1/ui/group/${groupId}`, updatedData, {
        withCredentials: true,
      })
      .then(() => {
        // Refetch the updated group data immediately
        axios
          .get<GroupDetailInterface>(`${BASE_URL}/api/v1/ui/group/${groupId}`, {
            withCredentials: true,
          })
          .then((response) => {
            setGroupDetail(response.data);
            setIsSaving(false);
            setIsUploading(false); // Stop uploading after success
            setIsEditing(false);
          })
          .catch(() => {
            handleError('Ошибка обновления данных', 'Не удалось обновить данные после сохранения.');
            setIsSaving(false);
            setIsUploading(false); // Stop uploading in case of error
          });
      })
      .catch(() => {
        handleError('Ошибка сохранения файла', 'Не удалось сохранить изменения с файлом.');
        setIsSaving(false);
        setIsUploading(false); // Stop uploading in case of error
      });
  };

  const handleDecline = (groupId: any, participantId: any) => {
    axios
      .post(`${BASE_URL}/api/v1/ui/group/${groupId}/${participantId}?accept=false`, null, {
        withCredentials: true,
      })
      .then(() => {
        setSuccessMessage('Вы удалили участника');
        setIsSuccessModalVisible(true);
        fetchData();
      })
      .catch(() => {
        handleError('Ошибка', 'Не удалось отклонить участника');
      });
  };

  const handleError = (header: string, message: string) => {
    setErrorHeader(header);
    setErrorMessage(message);
    setIsErrorModalVisible(true);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalVisible(false);
  };

  const handleAddDocumentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderCheckbox = (id: number) => (
    <Checkbox
      checked={selectedParticipants.includes(id)}
      onChange={() => handleSelectParticipant(id)}
    />
  );

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setGroupName(initialGroupName);
    setCompanyWebsite(initialCompanyWebsite);
    setDescription(initialDescription);
    setFee(initialFee);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedParticipants([]);
    } else {
      setSelectedParticipants(groupData.map((participant) => participant.orgId));
    }
    setSelectAll(!selectAll);
  };

  const handleCloseModal = () => {
    setIsSuccessModalVisible(false); // Hide the modal
    setIinValue(''); // Optionally clear the input
  };

  const handleSelectParticipant = (id: number) => {
    setSelectedParticipants((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const renderActions = (group: GroupMember) => {
    const { status, owner } = group;

    if (status.code === 'ACTIVE' && owner) {
      return null;
    } else if (status.code === 'ACTIVE' && !owner) {
      return (
        <img
          src={GroupIconDecline}
          alt='Decline'
          style={{ cursor: 'pointer' }}
          onClick={() => handleDecline(groupId, group.orgId)}
        />
      );
    } else if (status.code === 'ON_MODERATE') {
      return (
        <>
          <img
            src={GroupIconAccept}
            alt='Accept'
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => console.log('Accept action for', group.orgId)}
          />
          <img
            src={GroupIconDecline}
            alt='Decline'
            style={{ cursor: 'pointer' }}
            onClick={() => handleDecline(groupId, group.orgId)}
          />
        </>
      );
    } else if (status.code === 'INVITED') {
      return (
        <img
          src={GroupIconDecline}
          alt='Decline'
          style={{ cursor: 'pointer' }}
          onClick={() => handleDecline(groupId, group.orgId)}
        />
      );
    }
  };

  const renderBoxInfoRight = () => {
    if (groupDetail?.status.code === 'INVITED') {
      return (
        <InviteBox>
          Вас пригласили в эту группу
          <ButtonBox>
            <NewButton
              text='Отклонить приглашение'
              className='red'
              onClick={() => handleGroupAction(false)}
            />
            <NewButton text='Принять приглашение' onClick={() => handleGroupAction(true)} />
          </ButtonBox>
        </InviteBox>
      );
    } else if (groupDetail?.status.code === 'ON_MODERATE') {
      return <div>Ожидайте ответа владельца группы</div>;
    } else if (groupDetail?.status.code === 'ACTIVE' && !groupDetail?.owner) {
      return (
        <ButtonBox>
          <NewButton
            text='Покинуть группу'
            className='red'
            onClick={() => handleGroupAction(false)}
          />{' '}
          <TagRole>Вы участник</TagRole>
        </ButtonBox>
      );
    }
    return null;
  };

  if (!groupDetail) return <div>Loading...</div>;

  return (
    <Container>
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleCloseModal}
          title='Успех!'
          message={successMessage}
          buttonText='ОК'
        />
      )}

      {isErrorModalVisible && (
        <ErrorModal
          headerError={errorHeader}
          textError={errorMessage}
          buttonClose='Закрыть'
          onClick={handleCloseErrorModal}
        />
      )}

      <BreadCrumbsBox>
        <p>
          <a href='/groups'>Мои группы</a>
        </p>
        <p>{'>'}</p>
        <p>{groupDetail.name}</p>
      </BreadCrumbsBox>
      {groupDetail.owner ? (
        <>
          <SwitcherBox>
            <Tab
              active={activeTab === 'controlGroups'}
              onClick={() => setActiveTab('controlGroups')}>
              Управление группой
            </Tab>
            <Tab
              active={activeTab === 'settingGroups'}
              onClick={() => setActiveTab('settingGroups')}>
              Настройки группы
            </Tab>
          </SwitcherBox>
          {activeTab === 'controlGroups' && (
            <>
              <ControlGroups>
                <InputNew
                  title='Добавить участника'
                  value={iinValue}
                  onChange={handleIinChange}
                  error={iinError}
                  type='text'
                  placeholder='Введите ИИН/БИН участника'
                  maxWidth='500px'
                />
                <NewButton
                  text={isSendingInvitation ? 'Отправка...' : 'Отправить приглашение'}
                  onClick={handleSendInvitation}
                  disabled={iinValue.length !== 12 || isSendingInvitation} // Disable during loading
                  loading={isSendingInvitation} // Show loading state
                />
                <NewButton
                  className='red'
                  text={`Удалить участников (${selectedParticipants.length})`}
                  disabled={selectedParticipants.length === 0}
                />
              </ControlGroups>
              <Table>
                <thead>
                  <TableRowHeader>
                    <TableHeader width='50px'>
                      <Checkbox checked={selectAll} onChange={handleSelectAll} />
                    </TableHeader>
                    <TableHeader>Имя</TableHeader>
                    <TableHeader>ИИН/БИН</TableHeader>
                    <TableHeader>Статус</TableHeader>
                    <TableHeader>Действие</TableHeader>
                  </TableRowHeader>
                </thead>
                <tbody>
                  {groupData.map((group) => (
                    <TableRow key={group.orgId} style={{ cursor: 'pointer' }}>
                      <TableCell>{renderCheckbox(group.orgId)}</TableCell>
                      <TableCell>{group.name}</TableCell>
                      <TableCell>{group.biin}</TableCell>
                      <TableCell>
                        {group.status.code === 'INVITED' || group.status.code === 'ON_MODERATE' ? (
                          <StatusText color='blue'>{group.status.text_ru}</StatusText>
                        ) : (
                          <>
                            <StatusCircle color={group.owner ? '#29E584' : '#D8D8D8'} />
                            {group.owner ? 'Владелец' : 'Участник'}
                          </>
                        )}
                      </TableCell>
                      <TableCell>{renderActions(group)}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {activeTab === 'settingGroups' && (
            <SettingGroups>
              <SettingGroupsMainBox>
                <SettingGroupsMainBoxLeft>
                  <label>Логотип группы</label>
                  <Circle />
                  <div>
                    <label>Обновить логотип группы</label>
                    <p>Max file size is 500kb. Supported file types are .jpg and .png.</p>
                    <span>Загрузить файл</span>
                  </div>
                </SettingGroupsMainBoxLeft>
                <SettingGroupsMainBoxRight>
                  <InputNew
                    title='Название группы'
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder='Введите название группы'
                    disabled={!isEditing}
                    readOnly={!isEditing}
                  />
                  <InputNew
                    title='Сайт компании'
                    value={companyWebsite}
                    onChange={(e) => setCompanyWebsite(e.target.value)}
                    placeholder='Введите сайт компании'
                    disabled={!isEditing}
                    readOnly={!isEditing}
                  />
                  <InputNew
                    title='Реф. ссылка'
                    value={refLink}
                    onChange={(e) => setRefLink(e.target.value)}
                    placeholder='Введите реф. ссылку'
                    disabled
                    readOnly
                  />
                </SettingGroupsMainBoxRight>
              </SettingGroupsMainBox>

              <TextArea
                title='Описание'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Введите описание'
                disabled={!isEditing}
                className='maxApplication'
                readOnly={!isEditing}
              />

              <InputNew
                title='Комиссия брокера %'
                value={fee}
                onChange={(e) => setFee(e.target.value)}
                placeholder='Введите комиссию брокера'
                disabled={!isEditing}
                readOnly={!isEditing}
              />
              <SettingDocumentsBox>
                <SettingDocumentsMain>
                  <label>Список документов</label>
                  <SettingDocumentBox>
                    {groupDetail?.file?.uid && (
                      <>
                        <SettingDocument
                          onClick={() =>
                            groupDetail?.file?.uid && downloadFile(groupDetail.file.uid)
                          }
                          style={{ cursor: groupDetail?.file?.uid ? 'pointer' : 'default' }} // Добавляем курсор указателя, если файл есть
                        >
                          {' '}
                          <img src={SettingDocumentIcon} alt='SettingDocumentIcon' />
                          {`${groupDetail.file.title}`} {/* Используем file.name */}
                        </SettingDocument>
                        <SettingButtonChange onClick={handleAddDocumentClick}>
                          {isUploading ? 'Идет загрузка ...' : 'Заменить'}
                        </SettingButtonChange>
                        <input
                          type='file'
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileSelect}
                          accept='.png'
                        />
                      </>
                    )}
                  </SettingDocumentBox>
                  {!groupDetail.file?.title && (
                    <AddNewDocumentBox>
                      <p>Max file size is 500kb. Supported file types are .png.</p>
                      <NewButton
                        text='Добавить документ'
                        className='small'
                        onClick={handleAddDocumentClick}
                      />
                      <input
                        type='file'
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        accept='.png'
                      />
                    </AddNewDocumentBox>
                  )}
                </SettingDocumentsMain>
              </SettingDocumentsBox>
              <SettingButtonBox>
                {!isEditing ? (
                  <NewButton text='Редактировать' onClick={handleEditClick} />
                ) : (
                  <>
                    {!isSaving && (
                      <NewButton text='Отменить' onClick={handleCancelClick} className='white' />
                    )}
                    <NewButton
                      text={isSaving ? 'Сохранение...' : 'Сохранить'}
                      onClick={handleSaveClick}
                      disabled={isSaving}
                      loading={isSaving}
                    />
                  </>
                )}
              </SettingButtonBox>
            </SettingGroups>
          )}
        </>
      ) : (
        <MainBox>
          <Banner />
          <BoxInfo>
            <BoxInfoLeft>
              {groupDetail.logoFileId ? (
                <img src={groupDetail.logoFileId} alt={groupDetail.name} />
              ) : (
                <Logo />
              )}
              <BoxInfoDescription>
                <Title>{groupDetail.name}</Title>
                <SiteLink>
                  <span>Сайт</span>
                  <span>·</span>
                  <a
                    href={`https://${groupDetail.baseUrl}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {groupDetail.baseUrl}
                  </a>
                </SiteLink>
                <Participants>{groupDetail.count} участников</Participants>
              </BoxInfoDescription>
            </BoxInfoLeft>
            <BoxInfoRight>{renderBoxInfoRight()}</BoxInfoRight>
          </BoxInfo>
          <ContainerDescription>
            <label>Описание</label>
            <Description>{groupDetail.description}</Description>
          </ContainerDescription>
          <DocumentAndBrokerBlock>
            <DocumentBox>
              <label>Cписок документов</label>
              <SettingDocument
                onClick={() => groupDetail?.file?.uid && downloadFile(groupDetail.file.uid)}
                style={{ cursor: groupDetail?.file?.uid ? 'pointer' : 'default' }} // Добавляем курсор указателя, если файл есть
              >
                <img src={SettingDocumentIcon} alt='SettingDocumentIcon' />
                {groupDetail?.file?.title ? `${groupDetail.file.title}` : 'Файл отсутствует'}
              </SettingDocument>
            </DocumentBox>
            <BrokerBox>
              <label>Комиссия брокера</label>
              <Fee>{groupDetail.fee} %</Fee>
            </BrokerBox>
          </DocumentAndBrokerBlock>
        </MainBox>
      )}
    </Container>
  );
};
