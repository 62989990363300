import {
  Title,
  BoxHeader,
  ControlBox,
  Container,
  Table,
  TableRowHeader,
  TableHeader,
  TableRow,
  TableCell,
  StatusCircle,
  ActionFilterBox,
  BoxInputDescription,
  BoxInput,
  BoxInfoInput,
  ButtonsBox,
  FilterBox,
  DatePickerWrapper,
  StyledDatePicker,
  CalendarIcon,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSearch } from 'components/common/Input/InputSearch';
import { Pagination } from 'components/Pagination';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SuggestionsInterface } from './SuggestionsInvestorList.interface';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { NewButton } from 'components/common/Button';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { ru } from 'date-fns/locale';
import { formatNumberWithSpaces, removeSpacesFromNumber } from 'utils/numberFormat';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';
import Hint from 'components/common/Hint/Hint';

interface PageChangeEventArgs {
  selected: number;
}

const datePickerPopperProps = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 8], // настройте отступы
      },
    },
  ],
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const SuggestionsInvestorList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<SuggestionsInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  // Filter state
  const [requestNum, setRequestNum] = useState<string>('');
  const [minSum, setMinSum] = useState('');
  const [maxSum, setMaxSum] = useState('');
  const [minOfferTransfer, setMinOfferTransfer] = useState<number | null>(null);
  const [maxOfferTransfer, setMaxOfferTransfer] = useState<number | null>(null);
  const [minOfferPenaltyRate, setMinOfferPenaltyRate] = useState('');
  const [maxOfferPenaltyRate, setMaxOfferPenaltyRate] = useState('');
  const [minOfferRate, setMinOfferRate] = useState('');
  const [maxOfferRate, setMaxOfferRate] = useState('');
  const [minOfferPeriod, setMinOfferPeriod] = useState('');
  const [maxOfferPeriod, setMaxOfferPeriod] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState<Date | null>(null);
  const [dateCreateEnd, setDateCreateEnd] = useState<Date | null>(null);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [requestDtEndStart, setRequestDtEndStart] = useState<Date | null>(null);
  const [requestDtEndEnd, setRequestDtEndEnd] = useState<Date | null>(null);
  const [offerExtra, setOfferExtra] = useState<boolean | null>(null);

  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  const navigate = useNavigate();

  const transformedData = (data: any[]): SuggestionsInterface[] => {
    return data.map((item) => ({
      id: item.id,
      request: item.request,
      requestNum: item.requestNum,
      requestDtEnd: item.requestDtEnd,
      offerSum: item.offerSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerTransfer: item.offerTransfer,
      offerPeriod: item.offerPeriod,
      offerRate: item.offerRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerPenaltyRate: item.offerPenaltyRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      extraInfo: item.extraInfo,
      statusName: item.statusName || 'Не указан',
      overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerExtra: item.offerExtra || '',
      status: item.status || null,
      requestInfo: item.requestInfo || null,
    }));
  };

  const getFilters = () => {
    return {
      requestNum: requestNum || undefined,
      minSum: minSum || undefined,
      maxSum: maxSum || undefined,
      minOfferTransfer: minOfferTransfer || undefined,
      maxOfferTransfer: maxOfferTransfer || undefined,
      minOfferPenaltyRate: minOfferPenaltyRate || undefined,
      maxOfferPenaltyRate: maxOfferPenaltyRate || undefined,
      minOfferRate: minOfferRate || undefined,
      maxOfferRate: maxOfferRate || undefined,
      minOfferPeriod: maxOfferPeriod || undefined,
      maxOfferPeriod: maxOfferPeriod || undefined,
      dateCreateStart: dateCreateStart?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: dateCreateEnd?.toISOString().split('T')[0] || undefined,
      statuses: statuses.length > 0 ? statuses : undefined,
      requestDtEndStart: requestDtEndStart?.toISOString().split('T')[0] || undefined,
      requestDtEndEnd: requestDtEndEnd?.toISOString().split('T')[0] || undefined,
      offerExtra: offerExtra !== null ? offerExtra : undefined,
    };
  };

  const fetchData = useCallback(
    async (page = 0, filters = getFilters(), sort = sortConfig) => {
      setIsLoading(true); // Set loading to true before fetching data
      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        if (sort.field && sort.direction) {
          params.append('sort', `${sort.field},${sort.direction}`);
        }

        const response = await axios.post(
          `${BASE_URL}api/v1/cabinet/investor/request/offer/search?${params.toString()}`,
          filters,
          { withCredentials: true },
        );

        setTableData(transformedData(response.data.content));
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched
      }
    },
    [sortConfig],
  );

  useEffect(() => {
    fetchData(currentPage, getFilters(), sortConfig);
  }, [currentPage, sortConfig]);

  const handlePageChange = ({ selected }: PageChangeEventArgs) => {
    fetchData(selected, getFilters(), sortConfig);
    setCurrentPage(selected);
  };

  const handleSort = (field: string) => {
    setSortConfig((prevSortConfig) => {
      if (prevSortConfig.field === field) {
        return {
          field,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { field, direction: 'asc' };
    });
  };

  const applyFiltersAndSorting = () => {
    setCurrentPage(0);
    fetchData(0, getFilters(), sortConfig);
  };

  const clearFilters = () => {
    setRequestNum('');
    setMinSum('');
    setMaxSum('');
    setMinOfferTransfer(null);
    setMaxOfferTransfer(null);
    setMinOfferPenaltyRate('');
    setMaxOfferPenaltyRate('');
    setMinOfferRate('');
    setMaxOfferRate('');
    setMinOfferPeriod('');
    setMaxOfferPeriod('');
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setStatuses([]);
    setRequestDtEndStart(null);
    setRequestDtEndEnd(null);
    setOfferExtra(null);

    fetchData(0);
  };

  const filteredData = useMemo(() => {
    if (searchValue) {
      return tableData.filter((row) => row.requestNum.toString().includes(searchValue));
    }
    return tableData;
  }, [searchValue, tableData]);

  const calculateRemainingTime = (endDateString: string) => {
    const endDate = new Date(endDateString);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return { text: 'Время истекло', expired: true };
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return { text: `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`, expired: false };
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60)) / (1000 * 60));

    return { text: `${hours}:${minutes}`, expired: false };
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const handlePercentageInput = (value: string, setter: (value: string) => void) => {
    // Разрешаем только числа с точностью до двух десятичных знаков
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setter(value);
    }
  };

  return (
    <Container>
      <Title>Мои предложения</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={requestNum}
            onChange={(e) => setRequestNum(e.target.value)}
            placeholder='Введите номер сделки'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='Фильтр' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      <ActionFilterBox isOpen={isFilterBoxOpen}>
        <BoxInputDescription>
          <BoxInput>
            <p>Сумма займа</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='100 000'
                value={formatNumberWithSpaces(minSum)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setMinSum(rawValue);
                }}
                maxWidth='100px'
              />
              <span>До</span>
              <InputNew
                placeholder='999 999'
                value={formatNumberWithSpaces(maxSum)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setMaxSum(rawValue);
                }}
                maxWidth='100px'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Начало финансирования</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='0 дней'
                value={minOfferTransfer !== null ? minOfferTransfer.toString() : ''}
                onChange={(e) =>
                  setMinOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                }
                maxWidth='100px'
                type='number'
              />
              <span>До</span>
              <InputNew
                placeholder='20 дней'
                value={maxOfferTransfer !== null ? maxOfferTransfer.toString() : ''}
                onChange={(e) =>
                  setMaxOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                }
                maxWidth='100px'
                type='number'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Пеня за день %</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='0 %'
                value={minOfferPenaltyRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMinOfferPenaltyRate)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='2 %'
                value={maxOfferPenaltyRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMaxOfferPenaltyRate)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <p>Месячная ставка % </p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='1 %'
                value={minOfferRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMinOfferRate)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='5 %'
                value={maxOfferRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMaxOfferRate)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Срок финансирования</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='1 дня'
                value={minOfferPeriod}
                onChange={(e) => setMinOfferPeriod(e.target.value)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='25 дней'
                value={maxOfferPeriod}
                onChange={(e) => setMaxOfferPeriod(e.target.value)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <p>Дата предложения</p>
            <BoxInfoInput>
              <span>С</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={dateCreateStart}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setDateCreateStart(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
              <span>По</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  dateFormat='dd/MM/yyyy'
                  selected={dateCreateEnd}
                  onChange={(date: Date | null) => setDateCreateEnd(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Срок завершения</p>
            <BoxInfoInput>
              <span>С</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={requestDtEndStart}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setRequestDtEndStart(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
              <span>По</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  dateFormat='dd/MM/yyyy'
                  selected={requestDtEndEnd}
                  onChange={(date: Date | null) => setRequestDtEndEnd(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <ButtonsBox>
          <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
          <NewButton text='Применить фильтры' className='small' onClick={applyFiltersAndSorting} />
        </ButtonsBox>
      </ActionFilterBox>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <Table>
            <thead>
              <TableRowHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'requestNum'}
                  sortable={true}
                  width='100px'
                  direction={sortConfig.field === 'requestNum' ? sortConfig.direction : null}
                  onClick={() => handleSort('requestNum')}>
                  <Hint displayText='№ Сделки' text=' Идентификатор сделки' />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'offerSum'}
                  sortable={true}
                  direction={sortConfig.field === 'offerSum' ? sortConfig.direction : null}
                  onClick={() => handleSort('offerSum')}
                  className='right'>
                  <Hint displayText='Предл. сумма' text='Моя предложенная сумма' />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'offerPeriod'}
                  sortable={true}
                  width='120px'
                  direction={sortConfig.field === 'offerPeriod' ? sortConfig.direction : null}
                  onClick={() => handleSort('offerPeriod')}
                  className='right'>
                  <Hint
                    displayText='Срок. фин.'
                    text='Период, на который предоставляется финансирование'
                  />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'offerTransfer'}
                  sortable={true}
                  width='130px'
                  direction={sortConfig.field === 'offerTransfer' ? sortConfig.direction : null}
                  onClick={() => handleSort('offerTransfer')}
                  className='right'>
                  <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'offerRate'}
                  sortable={true}
                  width='120px'
                  direction={sortConfig.field === 'offerRate' ? sortConfig.direction : null}
                  onClick={() => handleSort('offerRate')}
                  className='right'>
                  <Hint displayText=' Мес. ставка' text='Процентная ставка за месяц' />
                </TableHeader>
                <TableHeader
                  isSorted={sortConfig.field === 'offerPenaltyRate'}
                  sortable={true}
                  width='120px'
                  direction={sortConfig.field === 'offerPenaltyRate' ? sortConfig.direction : null}
                  onClick={() => handleSort('offerPenaltyRate')}
                  className='right'>
                  <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                </TableHeader>
                <TableHeader className='right'>
                  <Hint displayText='Доход' text='Сумма дохода от финансирования сделки' />
                </TableHeader>
                {/* <TableHeader
                  isSorted={sortConfig.field === 'requestEnd'}
                  sortable={true}
                  direction={sortConfig.field === 'requestEnd' ? sortConfig.direction : null}
                  onClick={() => handleSort('requestEnd')}>
                  <Hint displayText='Срок завершения' text='Срок завершения предложения' />
                </TableHeader> */}

                <TableHeader sortable={false}>
                  <Hint
                    displayText='Статус предложения'
                    text='Статус вашего предложения по сделке'
                  />
                </TableHeader>
              </TableRowHeader>
            </thead>
            <tbody>
              {filteredData.map((row) => {
                const { text: remainingTime, expired } = calculateRemainingTime(row.requestDtEnd);

                return (
                  <TableRow key={row.id} onClick={() => navigate(`/suggestions/${row.id}`)}>
                    <TableCell>
                      <Link
                        style={{ color: '#5784F7', textDecoration: 'none' }}
                        to={`/request/${row.request}`}>
                        {row.requestNum}
                      </Link>
                    </TableCell>
                    <TableCell className='right'>{row.offerSum} ₸</TableCell>
                    <TableCell className='right'>{row.offerPeriod} д</TableCell>
                    <TableCell className='right'>{row.offerTransfer} д</TableCell>
                    <TableCell className='right'>{row.offerRate} %</TableCell>
                    <TableCell className='right'>{row.offerPenaltyRate} %</TableCell>
                    <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                    {/* <TableCell style={{ color: expired ? 'red' : 'inherit' }}>
                      <div>
                        <img src={expired ? IconRedTimer : IconBlackTimer} alt='Timer Icon' />
                        {remainingTime}
                      </div>
                    </TableCell> */}
                    <TableCell>
                      <div>
                        <StatusCircle $status={row.statusName || ''} />
                        {row.statusName}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.max(paginationInfo.totalPages, 1)}
            currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};

export default SuggestionsInvestorList;
