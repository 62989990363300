import axios from 'axios';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import { RequestData } from 'components/RequestDetailsDraft/RequestDetailsDraft.interface';
import {
  Container,
  BreadCrumbsBox,
  HeaderBox,
  TagRisk,
  SwitcherBox,
  Tab,
  ContainerContent,
  LeftBlock,
  RightBlock,
  InfoBoxRight,
  InfoBoxRightCard,
  ButtonBox,
  Timer,
  TagTime,
  MainInfoBox,
  MainInfoItem,
  MainInfoTitle,
  MainInfoContent,
  ContainerMain,
  ExpandableSection,
  ArrowIcon,
  MainInfoContentEdit,
  MainInfoItemText,
  ProgressBarContainer,
  ProgressBarFiller,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import {
  DraftBox,
  DraftButtonBox,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  DropdownArrow,
  ErrorTitle,
  MainDecorContent,
  MainDecorLogo,
  MainDecorInfoBox,
  MainDecorTitle,
  AcceptNewOffersNo,
  InfoDescription,
  DocLinkBox,
} from './RequestDetailsNew.style';
import { NewButton } from 'components/common/Button';
import IconRedTimer from 'assets/img/IconRedTimer.svg';
import CircleWhite from 'assets/img/CircleWhite.svg';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import IconHelpHouse from 'assets/img/IconHelpHouse.svg';
import IconDecor from 'assets/img/IconDecor.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { SentOffersList } from 'components/ApplicationInvestor';
import { OffersBusinessList } from 'components/OffersBusinessList';
import { ApplicationDocumentsList } from './ApplicationDocumentsList';
import { InputText } from 'components/common/Input/InputText';
import { Account } from 'components/NewCreateApplication/NewCreateApplication.interface';
import { File } from './ApplicationDocumentsList/ApplicationDocumentsList.interface';
import { ErrorModal } from 'components/common/ErrorModal';
import { TextArea } from 'components/common/TextArea/TextArea';
import CopyableText from 'components/common/CopyableText/CopyableText';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

const formatNumberWithSpaces = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const RequestDetailsNew = () => {
  const [requestData, setRequestData] = useState<RequestData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('mainInfo');
  const [remainingTime, setRemainingTime] = useState('00:00:00');
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoDecor, setIsMainInfoDecor] = useState(true);
  const [showOffers, setShowOffers] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<RequestData | null>(null);
  const [originalFiles, setOriginalFiles] = useState<File[]>([]);
  const [minAmountError, setMinAmountError] = useState('');
  const [amountError, setAmountError] = useState('');
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);
  const [contracts, setContracts] = useState<any[]>([]);
  const [selectedContract, setSelectedContract] = useState<any | null>(null);
  const [isContractDropdownOpen, setIsContractDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
  const [isGoalDropdownOpen, setIsGoalDropdownOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // State to manage saving process
  const [isPublishing, setIsPublishing] = useState(false); // State to manage publish button disabled state
  const [showErrorModal, setShowErrorModal] = useState(false); // State to manage the error modal visibility
  const [logoSrc, setLogoSrc] = useState<string | null>(null); // State to store the logo image source
  const [industrys, setIndustry] = useState<any[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any | null>(null);
  const [goals, setGoal] = useState<any[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<any | null>(null);

  const { id, tab } = useParams<{ id: string; tab?: string }>(); // Изменено для опционального параметра tab
  const navigate = useNavigate(); // Initialize useHistory hook for redirection

  const fetchRequestDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/cabinet/creditor/request/get?id=${id}`, {
        withCredentials: true,
      });

      setRequestData(response.data);
      setEditedData(response.data);
      setOriginalFiles(response.data.files);
      setSelectedAccount(response.data?.accountInfo || null);
      setSelectedContract(response.data?.contracts?.[0] || null);
      setSelectedIndustry(response.data.industry || null);
      setSelectedGoal(response.data.funding_goals || null);

      // Fetch the logo if logoFileId is present
      if (response.data.logoFileId) {
        const logoBlob = await fetchLogo(response.data.logoFileId);
        setLogoSrc(URL.createObjectURL(logoBlob));
      }
    } catch (err) {
      setError('Ошибка загрузки данных: ' + (err instanceof Error ? err.message : String(err)));
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!tab) {
      setActiveTab('mainInfo'); // Устанавливаем mainInfo как активную вкладку, если нет параметра tab
    }
  }, [tab]);

  const fetchLogo = async (logoFileId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/ui/file/${logoFileId}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching logo:', error);
      setError('Error fetching logo');
      throw error;
    }
  };

  const stopAcceptingOffers = async () => {
    try {
      await axios.get(`${BASE_URL}/cabinet/creditor/request/stop/${id}`, {
        withCredentials: true,
      });
      console.log('Прием предложений остановлен.');
      await fetchRequestDetails(); // Re-fetch request details to update the UI
    } catch (err) {
      console.error('Error stopping offers:', err);
      setError('Ошибка при остановке приема предложений.');
      setShowErrorModal(true); // Show the error modal
    }
  };

  useEffect(() => {
    fetchRequestDetails();
  }, [fetchRequestDetails]);

  useEffect(() => {
    if (requestData?.product === 'TENDER' && isEditing) {
      axios
        .get(`${BASE_URL}/ui/contract/list/egz`, {
          withCredentials: true,
        })
        .then((response) => setContracts(response.data))
        .catch((error) => {
          console.error('Error fetching contracts:', error);
          setError('Error fetching contracts');
        });
    }
  }, [requestData?.product, isEditing]);

  useEffect(() => {
    if (isEditing) {
      axios
        .get(`${BASE_URL}/ui/accounts`, { withCredentials: true })
        .then((response) => setAccounts(response.data))
        .catch((error) => {
          console.error('Error fetching accounts:', error);
          setError('Error fetching accounts');
        });
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      axios
        .get(`${BASE_URL}/dictionary/get?name=industry`, { withCredentials: true })
        .then((response) => setIndustry(response.data))
        .catch((error) => {
          console.error('Error fetching industry:', error);
          setError('Error fetching industry');
        });
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && requestData?.product === 'LOAN') {
      axios
        .get(`${BASE_URL}/dictionary/get?name=funding_goal`, { withCredentials: true })
        .then((response) => setGoal(response.data))
        .catch((error) => {
          console.error('Error fetching goals:', error);
          setError('Error fetching goals');
        });
    }
  }, [requestData?.product, isEditing]);

  const isDraft = requestData?.status === 'DRAFT';

  const isFormValid = useMemo(() => {
    if (!editedData) return false;
    const {
      requestedAmount,
      requestedMinAmount,
      requestPeriod,
      requestRate,
      accountInfo,
      loanPurposeProject,
      loanPurposeProjectDesc,
    } = editedData;
    return (
      requestedAmount > 0 &&
      Number(requestedMinAmount) > 0 &&
      Number(requestPeriod) > 0 &&
      Number(requestRate) > 0 &&
      accountInfo?.iban &&
      loanPurposeProject && // Ensure project name is filled
      loanPurposeProjectDesc && // Ensure project description is filled
      !amountError &&
      !minAmountError
    );
  }, [editedData, amountError, minAmountError]);

  const calculateTimeLeft = useCallback(() => {
    if (!requestData?.requestDtEnd) {
      return '00:00:00';
    }

    const endTime = new Date(requestData.requestDtEnd).getTime();
    const now = new Date().getTime();
    const difference = endTime - now;

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((difference / (1000 * 60)) % 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60)
        .toString()
        .padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

    return '00:00:00';
  }, [requestData]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const getWhiteLabelTag = useMemo(() => {
    if (requestData?.whiteLabels && requestData.whiteLabels.length > 0) {
      const whiteLabel = requestData.whiteLabels[0];
      switch (whiteLabel.code) {
        case 'OMARKET':
          return <TagsWhiteLabel text={whiteLabel.name} className='smartcash' />;
        case 'ALL':
          return <TagsWhiteLabel text={whiteLabel.name} className='all' />;
        default:
          return null;
      }
    }
    return <TagsWhiteLabel text='All' />;
  }, [requestData]);

  const handleShowOffers = useCallback(() => {
    setShowOffers(true);
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
    setResetFiles(false); // Ensure files are not reset when starting to edit
  };

  const handleCancelEdit = () => {
    if (requestData) {
      setIsEditing(false);
      setEditedData({ ...requestData, files: originalFiles }); // Reset edited data and files to original
      setSelectedAccount(requestData?.accountInfo || null); // Reset selected account to original
      setSelectedContract(requestData?.contracts?.[0] || null); // Reset the selected contract to original
      setMinAmountError(''); // Clear error message
      setAmountError(''); // Clear error message
      setResetFiles(true); // Trigger reset of files in the documents component
      setSelectedIndustry(requestData?.industry || null);
      setSelectedGoal(requestData.funding_goals || null);
    }
  };

  const handleSave = async () => {
    if (!editedData || !selectedAccount || minAmountError || amountError) return;
    setIsSaving(true); // Start the saving process
    const dataToSave = {
      id: editedData.id,
      account: String(selectedAccount.id),
      product: requestData?.product,
      requestedAmount: String(editedData.requestedAmount),
      requestPeriod: String(editedData.requestPeriod),
      requestRate: String(editedData.requestRate),
      requestedMinAmount: String(editedData.requestedMinAmount),
      loanPurposeProject: String(editedData.loanPurposeProject),
      loanPurposeProjectDesc: String(editedData.loanPurposeProjectDesc),
      logoFileId: String(editedData.logoFileId),
      docLink: String(requestData?.docLink),
      files: editedData.files.map((file) => ({
        file: file.file,
        code: file.code,
        title: file.title || '', // Ensure title is always a string
      })),
      contracts: selectedContract?.id
        ? [
            {
              id: selectedContract.id,
              num: selectedContract.contractNumberSys || selectedContract.num,
              type: 'egz',
            },
          ]
        : [],
      industry: {
        id: selectedIndustry.id,
      },
      funding_goals: selectedGoal?.id
        ? {
            id: selectedGoal.id,
          }
        : null,
    };

    try {
      await axios.post(`${BASE_URL}/cabinet/creditor/request/edit`, dataToSave, {
        withCredentials: true,
      });
      setRequestData({
        ...editedData,
        contracts: [
          { id: selectedContract?.id, num: selectedContract?.contractNumberSys, type: 'egz' },
        ],
      }); // Update the original data with edited data and selected contract
      setOriginalFiles(editedData.files); // Update the original files
      setIsEditing(false);
      setResetFiles(false); // Ensure files are not reset after saving
      fetchRequestDetails();
    } catch (err) {
      setError('Ошибка сохранения данных: ' + (err instanceof Error ? err.message : String(err)));
      setShowErrorModal(true); // Show the error modal
    } finally {
      setIsSaving(false); // End the saving process
    }
  };

  const handlePublish = async () => {
    setIsPublishing(true);
    try {
      await axios.get(`${BASE_URL}/cabinet/creditor/request/run/${id}`, {
        withCredentials: true,
      });
      await fetchRequestDetails();
    } catch (err) {
      setError('Ошибка публикации: Банковская выписка не валидна');
      setShowErrorModal(true); // Show the error modal
    } finally {
      setIsPublishing(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/cabinet/creditor/request/delete/${id}`, {
        withCredentials: true,
      });
      navigate('/applications'); // Redirect to applications list after deletion
    } catch (err) {
      setError('Ошибка при удалении: ' + (err instanceof Error ? err.message : String(err)));
      setShowErrorModal(true); // Show the error modal
    }
  };

  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
  };

  const toggleContractDropdown = () => {
    setIsContractDropdownOpen(!isContractDropdownOpen);
  };

  const toggleIndustryDropdown = () => {
    setIsIndustryDropdownOpen(!isIndustryDropdownOpen);
  };

  const toggleGoalDropdown = () => {
    setIsGoalDropdownOpen(!isGoalDropdownOpen);
  };

  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
    setIsAccountDropdownOpen(false);
  };

  const handleIndustrySelect = (industry: any) => {
    const formattedIndustry = {
      id: industry.id,
      code: industry.code,
      name: {
        text_ru: industry.name,
        text_kk: industry.name,
        text_en: industry.name,
      },
    };
    setSelectedIndustry(formattedIndustry);
    setIsIndustryDropdownOpen(false);
  };

  const handleGoalSelect = (goal: any) => {
    const formattedGoals = {
      id: goal.id,
      code: goal.code,
      name: {
        text_ru: goal.name,
        text_kk: goal.name,
        text_en: goal.name,
      },
    };
    setSelectedGoal(formattedGoals);
    setIsGoalDropdownOpen(false);
  };

  const handleContractSelect = (contract: any) => {
    setSelectedContract(contract);
    setIsContractDropdownOpen(false);
    validateAmounts(editedData?.requestedAmount, editedData?.requestedMinAmount, contract);
  };

  const handleMinAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinAmount = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        requestedMinAmount: newMinAmount,
      },
    );
    validateAmounts(editedData?.requestedAmount, newMinAmount, selectedContract);
  };

  const handleProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        loanPurposeProject: projectName,
      },
    );
  };

  const handleProjectDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const projectDescription = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        loanPurposeProjectDesc: projectDescription,
      },
    );
  };

  const handleRequestedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRequestedAmount = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        requestedAmount: newRequestedAmount,
      },
    );
    validateAmounts(newRequestedAmount, editedData?.requestedMinAmount, selectedContract);
  };

  const validateAmounts = (requestedAmount?: any, requestedMinAmount?: any, contract?: any) => {
    let newMinAmountError = '';
    let newAmountError = '';

    if (
      requestedAmount &&
      requestedMinAmount &&
      Number(requestedMinAmount) > Number(requestedAmount)
    ) {
      newMinAmountError = 'Минимальный порог входа не может превышать сумму займа';
    }

    if (contract) {
      const contractAmount = contract.sumNoNds || contract.contractSum || 0;
      if (requestedAmount && requestedAmount > contractAmount) {
        newAmountError = 'Сумма займа не может превышать сумму контракта';
      }
    }

    setMinAmountError(newMinAmountError);
    setAmountError(newAmountError);
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setEditedData(
        editedData && {
          ...editedData,
          requestRate: value,
        },
      );
    }
  };

  const handleFilesChange = (files: any) => {
    setEditedData((editedData) => {
      if (!editedData) return null;
      return {
        ...editedData,
        files,
      };
    });
  };

  const handleDeleteFile = (fileId: string) => {
    if (editedData) {
      const updatedFiles = editedData.files.filter((file) => file.file !== fileId);
      setEditedData({ ...editedData, files: updatedFiles });
    }
  };

  const calculatePercentage = (signedSum: any, remainingSum: any) => {
    if (!signedSum || !remainingSum || remainingSum === 0) return 0;
    return (signedSum / remainingSum) * 100;
  };

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    navigate(`/req/${id}/${newTab}`); // Меняем URL при смене вкладки
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {showOffers ? (
        <OffersBusinessList
          requestNumber={requestData?.num}
          requestId={id}
          requestedAmount={requestData?.requestedAmount}
          requestPeriod={requestData?.requestPeriod}
          requestRate={requestData?.requestRate}
        />
      ) : (
        <Container>
          <BreadCrumbsBox>
            <p>
              <a href='/applications'>Сделки</a>
            </p>
            <span>{'>'}</span>
            <p>Сделка {requestData?.num}</p>
          </BreadCrumbsBox>
          <HeaderBox>
            <div className='leftContent'>
              <h2>Сделка {requestData?.num}</h2>
              {getWhiteLabelTag}
              <TagRisk status={requestData?.status}>
                <img src={CircleWhite} alt='circle' />
                {requestData?.statusName}
              </TagRisk>
              {!isDraft && (
                <TagTime>
                  <img src={IconRedTimer} alt='timer' />
                  <Timer>{remainingTime}</Timer>
                </TagTime>
              )}
            </div>
            {!isDraft && <NewButton onClick={handleShowOffers} text='К предложениям' />}
          </HeaderBox>
          <SwitcherBox>
            <Tab active={activeTab === 'mainInfo'} onClick={() => handleTabChange('mainInfo')}>
              Основная информация
            </Tab>
            <Tab active={activeTab === 'documents'} onClick={() => handleTabChange('documents')}>
              Документы
            </Tab>
          </SwitcherBox>
          <ContainerContent>
            <LeftBlock>
              {activeTab === 'mainInfo' && (
                <ContainerMain>
                  <ExpandableSection>
                    <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
                      <img src={IconHelpHouse} alt='icon' />
                      Общая информация
                      <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
                    </MainInfoTitle>
                    {isMainInfoExpanded && (
                      <MainInfoBox>
                        {isEditing ? (
                          <MainInfoContentEdit>
                            <MainInfoItem>
                              <MainInfoItemText>Продукт</MainInfoItemText> {editedData?.productName}
                            </MainInfoItem>
                            {requestData?.product === 'TENDER' && (
                              <>
                                {requestData?.contracts?.[0]?.customerName || 'N/A'}
                                <MainInfoItem>
                                  <DropdownContainer ref={accountDropdownRef}>
                                    <label>Выбрать контракт</label>
                                    <DropdownHeader
                                      onClick={toggleContractDropdown}
                                      isOpen={isContractDropdownOpen}
                                      $hasContent={!!selectedContract}>
                                      {selectedContract
                                        ? `${selectedContract.num || selectedContract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(selectedContract.sumNoNds?.toString() || selectedContract.contractSum?.toString() || '0')} тенге )`
                                        : 'Выбрать контракт'}
                                      <DropdownArrow isOpen={isContractDropdownOpen} />
                                    </DropdownHeader>

                                    {isContractDropdownOpen && (
                                      <DropdownListContainer>
                                        {contracts.map((contract) => (
                                          <DropdownListItem
                                            key={contract.id}
                                            onClick={() => handleContractSelect(contract)}>
                                            {`${contract.contractNumberSys || 'N/A'} ( сумма договора ${formatNumberWithSpaces(contract.contractSum?.toString() || '0')} тенге )`}
                                          </DropdownListItem>
                                        ))}
                                      </DropdownListContainer>
                                    )}
                                  </DropdownContainer>
                                </MainInfoItem>
                              </>
                            )}
                            <MainInfoItem>
                              <DropdownContainer ref={accountDropdownRef}>
                                <label>Выбрать счет</label>
                                <DropdownHeader
                                  onClick={toggleAccountDropdown}
                                  isOpen={isAccountDropdownOpen}
                                  $hasContent={!!selectedAccount}>
                                  {selectedAccount ? selectedAccount.iban : 'Выбрать счет'}
                                  <DropdownArrow isOpen={isAccountDropdownOpen} />
                                </DropdownHeader>
                                {isAccountDropdownOpen && (
                                  <DropdownListContainer>
                                    {accounts.map((account) => (
                                      <DropdownListItem
                                        key={account.id}
                                        onClick={() => handleAccountSelect(account)}>
                                        {account.iban}
                                      </DropdownListItem>
                                    ))}
                                  </DropdownListContainer>
                                )}
                              </DropdownContainer>
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Запрошенная сумма</MainInfoItemText>
                              <InputText
                                value={editedData?.requestedAmount?.toString() || ''}
                                onChange={handleRequestedAmountChange}
                                type='number'
                              />
                              {amountError && <ErrorTitle>{amountError}</ErrorTitle>}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Минимальный порог входа</MainInfoItemText>
                              <InputText
                                value={editedData?.requestedMinAmount?.toString() || ''}
                                onChange={handleMinAmountChange}
                                error={minAmountError}
                                type='number'
                              />
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Длительность финансирования</MainInfoItemText>
                              <InputText
                                value={editedData?.requestPeriod?.toString() || ''}
                                onChange={(e) =>
                                  setEditedData(
                                    editedData && {
                                      ...editedData,
                                      requestPeriod: String(e.target.value),
                                    },
                                  )
                                }
                                type='number'
                              />
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItem>
                                <MainInfoItemText>Процентная ставка в месяц</MainInfoItemText>
                                <InputText
                                  value={editedData?.requestRate?.toString() || ''}
                                  onChange={handleRateChange}
                                  type='text'
                                />
                              </MainInfoItem>
                            </MainInfoItem>
                          </MainInfoContentEdit>
                        ) : (
                          <MainInfoContent>
                            <MainInfoItem>
                              <MainInfoItemText>Продукт</MainInfoItemText>{' '}
                              {requestData?.productName}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Минимальный порог входа</MainInfoItemText>{' '}
                              {requestData?.requestedMinAmount?.toLocaleString() || 'N/A'} ₸
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Длительность финансирования</MainInfoItemText>{' '}
                              {requestData?.requestPeriod} дней
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Запрошенная сумма</MainInfoItemText>{' '}
                              {requestData?.requestedAmount.toLocaleString()} ₸
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>№ Счета</MainInfoItemText>{' '}
                              {requestData?.accountInfo?.iban}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Процентная ставка в месяц</MainInfoItemText>{' '}
                              {requestData?.requestRate} %
                            </MainInfoItem>

                            {requestData?.product === 'LOAN' && (
                              <>
                                <MainInfoItem>
                                  <MainInfoItemText>Вид залога</MainInfoItemText>{' '}
                                  {requestData?.collateral?.name?.text_ru
                                    ? requestData.collateral.name.text_ru
                                    : 'Нет залога'}
                                </MainInfoItem>
                              </>
                            )}
                            {requestData?.product === 'TENDER' && (
                              <>
                                <MainInfoItem>
                                  <MainInfoItemText>Сумма контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.sumNoNds?.toLocaleString() || 'N/A'}{' '}
                                  ₸
                                </MainInfoItem>
                                <MainInfoItem>
                                  <MainInfoItemText>№ Контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.num || 'N/A'}
                                </MainInfoItem>
                                <MainInfoItem>
                                  <MainInfoItemText>Название контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.customerName || 'N/A'}
                                </MainInfoItem>
                              </>
                            )}
                          </MainInfoContent>
                        )}
                      </MainInfoBox>
                    )}
                  </ExpandableSection>
                  <ExpandableSection>
                    <MainInfoTitle onClick={() => setIsMainInfoDecor(!isMainInfoDecor)}>
                      <img src={IconDecor} alt='icon' />
                      Оформление
                      <ArrowIcon src={isMainInfoDecor ? IconArrowDown : IconArrow} alt='arrow' />
                    </MainInfoTitle>
                    {isMainInfoDecor && (
                      <MainInfoBox>
                        {isEditing ? (
                          <MainInfoContentEdit>
                            <MainInfoItem>
                              <MainInfoItemText>Название проекта</MainInfoItemText>
                              <InputText
                                value={editedData?.loanPurposeProject || ''}
                                onChange={handleProjectName}
                              />
                            </MainInfoItem>
                            <MainInfoItem>
                              <DropdownContainer>
                                <label>Выбрать индустрию</label>
                                <DropdownHeader
                                  onClick={toggleIndustryDropdown}
                                  isOpen={isIndustryDropdownOpen}
                                  $hasContent={!!selectedIndustry}>
                                  {selectedIndustry
                                    ? selectedIndustry.name.text_ru
                                    : 'Выбрать индустрию'}
                                  <DropdownArrow isOpen={isIndustryDropdownOpen} />
                                </DropdownHeader>
                                {isIndustryDropdownOpen && (
                                  <DropdownListContainer>
                                    {industrys.map((industry) => (
                                      <DropdownListItem
                                        key={industry.id}
                                        onClick={() => handleIndustrySelect(industry)}>
                                        {typeof industry.name === 'string'
                                          ? industry.name
                                          : industry.name.text_ru}
                                      </DropdownListItem>
                                    ))}
                                  </DropdownListContainer>
                                )}
                              </DropdownContainer>
                            </MainInfoItem>
                            {requestData?.product === 'LOAN' && (
                              <MainInfoItem>
                                <DropdownContainer>
                                  <label>Выбрать цель займа</label>
                                  <DropdownHeader
                                    onClick={toggleGoalDropdown}
                                    isOpen={isGoalDropdownOpen}
                                    $hasContent={!!selectedGoal}>
                                    {selectedGoal ? selectedGoal.name.text_ru : 'Выбрать цель'}
                                    <DropdownArrow isOpen={isGoalDropdownOpen} />
                                  </DropdownHeader>
                                  {isGoalDropdownOpen && (
                                    <DropdownListContainer>
                                      {goals.map((goal) => (
                                        <DropdownListItem
                                          key={goal.id}
                                          onClick={() => handleGoalSelect(goal)}>
                                          {typeof goal.name === 'string'
                                            ? goal.name
                                            : goal.name.text_ru}
                                        </DropdownListItem>
                                      ))}
                                    </DropdownListContainer>
                                  )}
                                </DropdownContainer>
                              </MainInfoItem>
                            )}
                            <MainInfoItem>
                              <MainInfoItemText>Описание проекта</MainInfoItemText>
                              <TextArea
                                placeholder='Кратко расскажите историю своего бизнеса и опишите детально проект. Качественно описанные проекты привлекают на 30% больше инвестиций.'
                                value={editedData?.loanPurposeProjectDesc || ''}
                                onChange={handleProjectDescription}
                                className='maxApplication'
                              />
                            </MainInfoItem>
                          </MainInfoContentEdit>
                        ) : (
                          <MainDecorContent>
                            {logoSrc && <MainDecorLogo src={logoSrc} alt='logo' />}
                            <MainDecorInfoBox>
                              <MainDecorTitle>{requestData?.loanPurposeProject}</MainDecorTitle>
                              {requestData?.funding_goals?.name?.text_ru ? (
                                <div>
                                  <p>Цель займа:</p>
                                  {requestData.funding_goals.name.text_ru}
                                </div>
                              ) : (
                                <div>
                                  <p>Цель займа:</p>
                                  Исполнение тендера
                                </div>
                              )}

                              <div>
                                <p>Индустрия:</p>
                                {requestData?.industry?.name?.text_ru}
                              </div>
                              <div>
                                <p>Описание:</p>
                                <InfoDescription>
                                  {' '}
                                  {requestData?.loanPurposeProjectDesc}
                                </InfoDescription>
                              </div>
                            </MainDecorInfoBox>
                          </MainDecorContent>
                        )}
                      </MainInfoBox>
                    )}
                  </ExpandableSection>
                </ContainerMain>
              )}
              {activeTab === 'documents' && (
                <ApplicationDocumentsList
                  files={editedData?.files ?? []}
                  isEditing={isEditing}
                  onFilesChange={handleFilesChange}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  resetFiles={resetFiles}
                  onDeleteFile={handleDeleteFile}
                  docLink={requestData?.docLink}
                  requestData={requestData} // Pass requestData here
                  selectedAccount={selectedAccount} // Pass selectedAccount here
                  selectedContract={selectedContract} // Pass selectedContract here
                  selectedIndustry={selectedIndustry} // Pass selectedIndustry here
                  selectedGoal={selectedGoal} // Pass selectedGoal here
                />
              )}
            </LeftBlock>
            <RightBlock>
              {isDraft ? (
                <DraftBox>
                  <h4>Сделка находится в статусе черновик</h4>
                  <p>Опубликуйте чтобы инвесторы могли сделать предложение</p>
                </DraftBox>
              ) : (
                <InfoBoxRight>
                  <InfoBoxRightCard>
                    <div>
                      <p>Полученно предложений:</p>
                      <span>{requestData?.offerCount} </span>
                    </div>
                    <div>
                      <p>Принято предложений на сумму:</p>
                      <span>{requestData?.approvedAmount?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                  </InfoBoxRightCard>
                  <InfoBoxRightCard>
                    <div>
                      <p>Осталось собрать по сделке:</p>
                      <span>{requestData?.remainingSum?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                    <div>
                      <p>Подписано договоров на сумму:</p>
                      <span>{requestData?.signedContractSum?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                  </InfoBoxRightCard>
                  <ProgressBarContainer>
                    <ProgressBarFiller
                      style={{
                        width: `${calculatePercentage(requestData?.signedContractSum, requestData?.remainingSum)}%`,
                      }}
                    />
                  </ProgressBarContainer>
                </InfoBoxRight>
              )}
              {!isDraft ? (
                <ButtonBox>
                  {requestData?.acceptNewOffers ? (
                    <NewButton text='Остановить прием предложений' onClick={stopAcceptingOffers} />
                  ) : (
                    <AcceptNewOffersNo>Прием предложений остановлен</AcceptNewOffersNo>
                  )}
                </ButtonBox>
              ) : (
                <DraftButtonBox>
                  {isEditing ? (
                    <>
                      <NewButton
                        onClick={handleSave}
                        text='Сохранить'
                        disabled={
                          !isFormValid ||
                          !!minAmountError ||
                          !!amountError ||
                          isUploading ||
                          isSaving
                        }
                        loading={isSaving}
                      />
                      <NewButton onClick={handleCancelEdit} text='Отменить' className='red' />
                    </>
                  ) : (
                    <>
                      <NewButton onClick={handleEdit} text='Редактировать' className='white' />
                      <NewButton
                        onClick={handlePublish}
                        text='Опубликовать'
                        disabled={isPublishing}
                      />
                      <NewButton onClick={handleDelete} text='Удалить' className='red' />
                    </>
                  )}
                </DraftButtonBox>
              )}
              {!isDraft && <SentOffersList ids={id} num={requestData?.num} />}
            </RightBlock>
          </ContainerContent>
          {showErrorModal && (
            <ErrorModal
              headerError='Ошибка'
              textError={error}
              buttonClose='Закрыть'
              onClick={() => setShowErrorModal(false)}
            />
          )}
        </Container>
      )}
    </>
  );
};
