import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppContext } from 'contexts/AppContext';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { NewButton } from 'components/common/Button';
import { TitleBox } from './ContractBusiness.style';
import { AVREntry, AVRProps } from './AVR.interface';
import { useFileDownload } from '../../utils/hooks';
import { Box } from './ContractSchedule.style';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const AVR: React.FC<AVRProps> = ({ idContract }) => {
  const { userDataLight } = useAppContext();
  const [data, setData] = useState<AVREntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingFileId, setLoadingFileId] = useState<string | null>(null);
  const { downloadFile } = useFileDownload();

  const endpoint =
    userDataLight.organizationRole === 'INVESTOR'
      ? `${BASE_URL}/api/v1/cabinet/investor/`
      : `${BASE_URL}/api/v1/cabinet/creditor/`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${endpoint}avr/find_all?contractId=${idContract}`, {
          withCredentials: true,
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Данные отсутствуют');
        setLoading(false);
      }
    };

    fetchData();
  }, [idContract]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box>
      <TitleBox>
        <h2>АВР</h2>
      </TitleBox>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Номер АВР</TableHeader>
            <TableHeader>Дата платежа</TableHeader>
            <TableHeader>Сумма</TableHeader>
            <TableHeader>Контрагент 1</TableHeader>
            <TableHeader>Контрагент 2</TableHeader>
            <TableHeader>Ссылка АВР</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.number}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.sum}</TableCell>
              <TableCell>{row.businessName}</TableCell>
              <TableCell>{row.investorName}</TableCell>
              <TableCell>
                <NewButton
                  text={loadingFileId === row.fileId ? 'Loading' : 'Скачать'}
                  onClick={() => downloadFile(row.fileId)}
                  disabled={loadingFileId === row.fileId}
                />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};
